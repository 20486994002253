import axios from "axios";
import baseUrl from "./constans";

let requestUrl=baseUrl;
axios.defaults.timeout=30000;

//统一配置，创建请求
let httpreqs=axios.create({
    baseURL:requestUrl,
    responseType:'json',
    validateStatus(status){
        //200 以外认定为失败
        return status===200
    }
})

// 拦截request
httpreqs.interceptors.request.use(config=>{
    return config;
},(error=>{
    return Promise.reject(error);
}));

//拦截response
httpreqs.interceptors.response.use(config=>{
    return config;
},error => {
    return Promise.reject(error);
});

const request={
    get(url){
        let _url=`${baseUrl}${url}`;
        try {
            let rsp = httpreqs.get(_url).catch(function (error) {
                console.log(error);
            });
            return rsp
        }
        catch (e) {
            console.log(e);
            return "";
        }
    },
    post(url,params){
        return new Promise((resolve, reject) => {
            let _url=`${baseUrl}${url}`;
            // console.log(_url);
            httpreqs.post(_url,params,{
                headers:{
                    'Content-Type':'application/json'
                }
            }).then(response=>{
                resolve(response.data);
            },error=>{
                reject(error);
            })
        });
    },
    upload(url,params){
        return new Promise((resolve,reject)=>{
            let _url=`${baseUrl}${url}`;
            console.log(params)
            httpreqs.post(_url,params,{
                headers:{
                    'Content-Type':'multipart/form-data;charset=utf-8'
                }
            }).then(response=>{
                resolve(response.data);
            },error=>{
                reject(error);
            })
        });
        // return httpreqs.post(_url,params,{
        //    headers:{
        //        'Content-Type':'multipart/form-data'
        //    }
        // });
    }
}

export default request;

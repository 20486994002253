<template>
    <div class="tar_root">
<!--        显示区域的容器-->
        <div class="tabbar_container">
            <slot name="container"></slot>
        </div>
<!--        底部信息-->
        <div :class="['tar_default_style',rootCss]">
            <slot name="items"></slot>
        </div>
    </div>
</template>
<script>
    export default {
        name:"tabbar",
        props:[
            'tabHeight'
        ],
        components:{},
        data(){
            return {
                rootCss:'tabbar_height_100',
            };
        },
        created(){
            if(this.tabHeight){
                this.rootCss=this.tabHeight;
            }
        },
        methods:{
            currentPageIndex(idx){
                console.log(idx);
            }
        }
    }
</script>
<style>
    .tar_root{
        width: 750px;
        display: flex;
        flex-direction: column;
        flex:1;
    }
    /*底部信息*/
    .tar_default_style{
        width: 750px;
        box-shadow: 0px 2px 4px rgba(100,100,100,0.5);
        background-color: #F8F8F8;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding-top: 8px;
        padding-bottom: 8px;
        position:fixed;
        bottom: 0;
        left: 0;
        z-index: 10;
        flex:1 auto;
    }
    .tabbar_height_100{
        height: 100px;
    }

    /*容器部分*/
    .tabbar_container{
        width: 750px;
        overflow: scroll;
        flex:1 auto;
        /*display: flex;*/
        /*flex-direction: column;*/
        /*position:relative;*/
    }

    .tabbar_container::-webkit-scrollbar{
        display:none;/*隐藏滚动条*/
    }
</style>

<template>
    <div class="home">
        <div class="home_top bgwhite">
            <img src="assets/laba.png" class="imglaba"/>
            <broadcast />
        </div>
        <div class="home_top">
            <Banner :images="images" @toShowContext="toShowContext" />
        </div>

        <div class="home_top iconList">
            <div class="iconItem">
                <img src="assets/icon.png" class="iconimg" />
                <div class="iconLabel">时效保障</div>
            </div>

            <div class="iconItem iconItemSp">
                <img src="assets/icon.png" class="iconimg" />
                <div class="iconLabel">服务保障</div>
            </div>

            <div class="iconItem iconItemSp">
                <img src="assets/icon.png" class="iconimg" />
                <div class="iconLabel">质量保障</div>
            </div>

            <div class="iconItem iconItemSp">
                <img src="assets/icon.png" class="iconimg" />
                <div class="iconLabel">价格保障</div>
            </div>
        </div>

        <div class="home_card_extand">
            <Swipe :stopPropagation="true" :auto="0" :defaultIndex="0" class="menuCard" style="box-shadow: 0px 2px 20px rgba(100,100,100,0.5);">
                <SwipeItem>
                    <div class="menuCard">
                        <div class="menuCardRow">
                            <div class="menuCardRowItem" @click="toItemPage(1)">
                                <img src="assets/shebeiweixiu_1.png" class="menuCardRowItemImg"/>
                                <div class="menuCardRowLabel">设备维修</div>
                            </div>
                            <div class="menuCardRowItem menuCardRowItemSp"  @click="toItemPage(2)">
                                <img src="assets/shebeibaoyang_1.png" class="menuCardRowItemImg" style="box-shadow: 0px 2px 4px #548dcd;"/>
                                <div class="menuCardRowLabel">设备保养</div>
                            </div>
                            <div class="menuCardRowItem menuCardRowItemSp" @click="toItemPage(3)">
                                <img src="assets/shebeigaizao_1.png" class="menuCardRowItemImg"/>
                                <div class="menuCardRowLabel">设备改造</div>
                            </div>
                            <div class="menuCardRowItem menuCardRowItemSp" @click="toItemPage(5)">
                                <img src="assets/xiangmuwaibao_1.png" class="menuCardRowItemImg"  style=" box-shadow: 0px 2px 4px #397ec8;"/>
                                <div class="menuCardRowLabel">项目外包</div>
                            </div>
                        </div>
                        <div class="menuCardRow menuCardRowBottom">
                            <div class="menuCardRowItem"  @click="toItemPage(4)">
                                <img src="assets/xinjingshebei_1.png" class="menuCardRowItemImg" style="box-shadow: 0px 2px 4px #d59341;"/>
                                <div class="menuCardRowLabel">新进设备</div>
                            </div>
                            <div class="menuCardRowItem menuCardRowItemSp" @click="toPx">
                                <img src="assets/jinengpeixun_1.png" class="menuCardRowItemImg" style="box-shadow: 0px 2px 4px #dc6b44;"/>
                                <div class="menuCardRowLabel">技能培训</div>
                            </div>
                            <div class="menuCardRowItem menuCardRowItemSp" @click="toBeiPinBeiJian">
                                <img src="assets/beipingbeijian_1.png" class="menuCardRowItemImg" style="box-shadow: 0px 2px 4px #5265c8;"/>
                                <div class="menuCardRowLabel">备品备件</div>
                            </div>
                            <div class="menuCardRowItem menuCardRowItemSp" @click="toHezuo">
                                <img src="assets/hezuohuoban_1.png" class="menuCardRowItemImg"  style="box-shadow: 0px 2px 4px #4d99dc;"/>
                                <div class="menuCardRowLabel">合作伙伴</div>
                            </div>
                        </div>
                    </div>
                </SwipeItem>
                <SwipeItem>
                    <div class="menuCard menuCardLeft">
                        <div class="menuCardRow menuCardRowLeft">
                            <div class="menuCardRowItem" @click="toCase">
                                <img src="assets/anlifengxaing_1.png" class="menuCardRowItemImg"/>
                                <div class="menuCardRowLabel">案例分享</div>
                            </div>
                        </div>
                    </div>
                </SwipeItem>
            </Swipe>
        </div>

        <div class="home_card_extand home_news">
            <div class="menuCard news_card" style="box-shadow: 0px 2px 20px rgba(100,100,100,0.5);">
                <div class="home_news_title">
                    <div class="tipstitle">篱笆匠人·最新动态</div>
<!--                    <div class="tipsmenu">...</div>-->
                </div>

                <div class="home_news_item" v-for="(item,idx) in this.newList" :key="'item'+idx" @click="toReqDetail(item)">
                    <img :src="(item.pictures!=null && item.pictures.length>0)?item.pictures[0]:'assets/demo_i1.png'" class="home_news_item_img" />

                    <div class="home_news_item_contents">
                        <div class="home_news_item_contents_title">{{item.postName}}</div>
                        <div class="home_news_item_contents_items">
                            <img src="assets/location1.png"/>
                            <div class="home_news_item_contents_items_label home_news_item_contents_items_txt home_news_item_contents_items_location">
                                {{item.address}}
                            </div>
                        </div>
                        <div class="home_news_item_contents_items">
                            <div class="home_news_item_contents_items_label">服务类型</div>
                            <div class="home_news_item_contents_items_label home_news_item_contents_items_txt" style="color:#226DF8">
                                {{item.categoryName}}
                            </div>
                        </div>
                        <div class="home_news_item_contents_items">
                            <div class="home_news_item_contents_items_label">服务日期</div>
                            <div class="home_news_item_contents_items_label home_news_item_contents_items_txt">
                                {{item.time}}
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div>
</template>

<script>
    document.title="篱笆匠人"
    import Banner from "../xyg/banner/Banner";
    import Vue from 'vue';
    import broadcast from "../xyg/broadcast/broadcast";
    import { Swipe, SwipeItem } from 'mint-ui';

    export default {
        name: "Home",
        props:{
            rule:String
        },
        components:{
            Banner,
            broadcast,
            Swipe,
            SwipeItem
        },
        data(){
            return {
                images:[],
                newList:[],
            }
        },
        mounted(){
            this.init();
            this.initNews();
        },
        methods:{
            init(){
                //加载banner
                let url="/api/banner";
                this.$post(url,null)
                    .then(rsp=>{
                        if(rsp.code==200 && rsp.success){
                            this.images=rsp.data;

                            // this.wx.previewImage({
                            //     current:this.images[0].picUrl,
                            //     urls:[this.images[0].picUrl,this.images[1].picUrl,this.images[2].picUrl]
                            // });
                        }
                    })
                    .catch(e=>{
                        console.log(e);
                    });
            },
            initNews(){
                let url='/api/product/pageH5Latest';
                let data=null;
                this.$post(url,data)
                    .then(rsp=>{
                        if(rsp.code==200 && rsp.success){
                            let rows=rsp.data;
                            if(rows!=null && rows.length>0){
                                rows.forEach(t=>{
                                    this.newList.push(t);
                                })
                            }
                        }
                    })
                    .catch(e=>{
                        console.log(e);
                    })
            },
            toItemPage(type){
                this.$router.push({path:'/ReqList',name:'ReqList',query:{
                     cardType:type
                    }
                })
            },
            toHezuo(){
                this.$router.push({path:'/hezuo'})
            },
            toBeiPinBeiJian(){
                this.$router.push({path:'/bpbj'})
            },
            toPx(){
                this.$router.push({path:'/px'})
            },
            toCase(){
                this.$router.push({path:'/case'})
            },
            toShowContext(content){
                this.registerComponent().then(component=>{
                    let bannerContent=new component({
                        data:function () {
                            return {
                                txt:content
                            }
                        }
                    });
                    let control={name:'JsOrderAction',component: bannerContent};
                    this.$emit('showOrderAction',control);

                }).catch(e=>{
                        console.log(e);
                    })
            },
            registerComponent(){
                return import(`../xyg/banner/BannerContent.vue`).then(component=>{
                    return Vue.extend(component.default);
                });
            },
            toReqDetail(item){
                let cardType=0;
                if(item.categoryName=='设备维修'){
                    cardType=1;
                }
                if(item.categoryName=='设备保养'){
                    cardType=2;
                }
                if(item.categoryName=='设备改造'){
                    cardType=3;
                }
                if(item.categoryName=='项目外包'){
                    cardType=5;
                }
                if(item.categoryName=='新进设备'){
                    cardType=4;
                }
                this.$router.push({path:'/ReqDetail',name:'ReqDetail',query:{
                        pid:item.id,
                        cardType:cardType
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .home{
        /*height: 100%;*/
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 750px;
        /*height: 100vh;*/
        overflow: scroll;
        padding-bottom: 160px;
    }
    .home::-webkit-scrollbar{
        display:none;/*隐藏滚动条*/
    }

    .home_top{
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 20px;
        height: 280px;
        width: 710px;
        display: flex;
    }

    .bgwhite{
        background-color: white;
        height: 40px !important;
        border-radius: 10px;
        flex-direction: row;
        align-items: center;
    }

    .imglaba{
        width: 28px;
        height: 28px;
        margin-left: 20px;
        margin-top: 6px;
        margin-bottom: 6px;
    }

    .iconList {
        height: 34px !important;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .iconItem{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .iconItemSp{
        margin-left: 18px;
    }

    .iconimg{
        width: 34px;
        height: 34px;
    }
    .iconLabel{
        margin-left: 4px;
        font-size: 22px;
    }

    .home_menu{
        overflow: scroll;
        width: 750px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .home_menu::-webkit-scrollbar{
        display:none;/*隐藏滚动条*/
    }

    .home_menucontainer{
        margin-left: 20px;
        margin-right: 20px;
        width: 710px;
        display: flex;
        flex-direction: column;
        margin-top: 28px;
    }

    .home_card{
        border-radius: 10px;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
        width: 710px;
        box-shadow: 0px 2px 20px rgba(100,100,100,0.5);
        z-index: 10;
        /*position: absolute;*/
        height: 560px;
    }

    .home_card_extand{
        margin-top: 20px;
        width: 750px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 480px;
    }

    /deep/ .mint-swipe{

    }
    /deep/ .mint-swipe .mint-swipe-items-wrap{
        width: 100%;
    }
    /deep/  .mint-swipe .mint-swipe-indicators{
    }
    /deep/ .mint-swipe .mint-swipe-indicator{
        background-color: #4883D9 !important;
        opacity:unset !important;
    }
    /deep/ .mint-swipe .mint-swipe-indicator.is-active{
        background-color: #4883D9 !important;
        width: 38px;
        border-radius: 50px;
    }

    .menuCard{
        border-radius: 10px;
        background-color: white;
        width: 710px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
    .menuCardRow{
        flex: 1;
        margin-top: 36px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .menuCardRowLeft{
        /*justify-content:flex-start !important;*/
        margin-left: 40px;
    }
    .menuCardLeft{
        align-items: flex-start !important;
    }
    .menuCardRowBottom{
        margin-bottom: 46px;
    }
    .menuCardRowItem{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .menuCardRowItemSp{
        margin-left: 50px;
    }
    .menuCardRowItemImg{
        width: 120px;
        height: 120px;
        border-radius: 60px;
        box-shadow: 0px 2px 4px rgba(230,96,102,0.5);
    }
    .menuCardRowLabel{
        margin-top: 16px;
        font-size: 28px;
    }


    .home_title{
        margin-left: 60px;
        margin-top: 26px;
        font-size: 30px;
        letter-spacing: 4px;
        font-weight: bolder;
    }
    .home_items{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        margin-top: 34px;
    }

    .home_item{
        flex:1 1 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .home_item_container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 150px;
        width: 150px;
        border-radius: 8px;
        box-shadow: 0px 2px 2px rgba(117,164,245,0.5);
    }

    .home_item img{
        width: 100px;
        height: 100px;
    }
    .home_item span{
        margin-top: 4px;
    }

    .home_clear{
        box-shadow:none !important;
    }

    .home_card_item_letterspace{
        margin-top: 70px !important;
    }

    .home_card_letterspace{
        margin-top: 30px;
        margin-bottom: 160px;
    }

    .home_news{
        margin-top: 30px !important;
        height: unset !important;
    }
    .news_card{
        align-items: unset !important;
    }
    .home_news_title{
        flex:1;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        margin-left: 40px;
        margin-right: 40px;
        font-size: 30px;
        font-weight: bold;
        letter-spacing: 4px !important;
    }
    .tipstitle{
        flex:1;
    }
    .tipsmenu{
        width: 30px;
    }

    .home_news_item{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .home_news_item_img{
        width: 190px;
        height: 176px;
        margin-left: 40px;
    }

    .home_news_item_contents{
        flex:1;
        margin-left: 54px;

    }
    .home_news_item_contents_title{
        font-size: 26px;
        font-weight: bold;
    }
    .home_news_item_contents_items img{
        width: 24px;
        height: 24px;
    }
    .home_news_item_contents_items_location{
        font-size: 22px !important;
    }

    .home_news_item_contents_items{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 16px;
    }
    .home_news_item_contents_items_txt{
        margin-left: 16px;
    }
    .home_news_item_contents_items_label{
        font-size: 26px;
        color:#A79F9F;
        letter-spacing: 2px;
    }

</style>

<template>
    <div class="banner">
        <transition-group tag='ul' class="slide" name='image'>
            <li v-for='(item,index) in images' :key='"li"+index' v-show='index===mark'>
                <a><img :src="item.picUrl" @click="showContent(item.content)"></a>
            </li>
        </transition-group>
        <div class="bullet">
            <span v-for='(item,index) in images.length' :key='index' :class="{'active':index===mark}" @click='change(index)'></span>
        </div>
    </div>
</template>

<script>
    //images [{image:'',toUrl:''}]
    export default {
        name: "Banner",
        props:{
            images:Array
        },
        data(){
            return {
                mark:0,
                timer:null,
            }
        },
        created() {
            this.play()
        },
        methods:{
            change(i){
                this.mark = i
            },
            autoPlay(){
                this.mark++
                if(this.mark ===this.images.length){
                    this.mark = 0
                    return
                }
            },
            play(){
                setInterval(this.autoPlay, 3000)
            },
            showImg(path){ //图片预览
                let img=[];
                this.images.forEach(t=>{
                    img.push(t.picUrl);
                });

                this.$wx.previewImage({
                    current:path,
                    urls:img
                });
            },
            showContent(content){
                this.$emit('toShowContext',content);
            },
        }
    }
</script>

<style scoped>
    .banner {
        margin: 0 auto;
        overflow: hidden;
        position: relative;
        width: 710px;
        height: 280px;
        border-radius: 10px;
    }

    .slide {
        width: 710px;
        height: 280px;
        list-style: none;
        margin:0;
        padding:0;
    }

    li {
        position: absolute;
        list-style: none;
    }

    img {
        width: 710px;
        height: 280px;
    }

    .bullet {
        width: 710px;
        bottom: 8px;
        margin: 0 auto;
        text-align: center;
        z-index: 10;
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 40px;
        /*background-color: red;*/
        position: absolute;
    }

    span {
        width:14px;
        height:14px;
        background: white;
        display: inline-block;
        border-radius:50%;
        margin-left: 16px;
    }

    .active {
        background: #900000;
    }

    .image-enter-active {
        transform: translateX(0);
        transition: all 1s ease;
    }

    .image-leave-active {
        transform: translateX(-100%);
        transition: all 1s ease;
    }

    .image-enter {
        transform: translateX(100%)
    }

    .image-leave {
        transform: translateX(0)
    }
</style>

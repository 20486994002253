let keys={};
keys.userTypeStr={};
keys.openId="OPENID";//用户的openid key
keys.nickName="NICKNAME";
keys.headUrl="HEADURL";
keys.userType="USERTYPE";
keys.isReader="ISREADER";
keys.userTypeStr.gongsi="公司";
keys.userTypeStr.jishi="技师";
keys.getWxCodeUrl="https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx54b7d125a8358098&redirect_uri={0}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";//获取用户信息
// keys.getWxCodeUrl="https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx35b27bce7376e251&redirect_uri={0}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";//获取用户信息
keys.webSite="http://h5.xingship.com";
// keys.webSite="http://127.0.0.1:8080";

export default keys;

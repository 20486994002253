<template>
    <div class="order_card">
<!--        角标-->
        <div class="order_card_jiaobiao" v-if="showJiaoBiao && orderItem.statusName=='已完成'">
            <div class="order_card_jiaobiao_rect"></div>
            <div class="order_card_jiaobiao_desc">已完成</div>
        </div>
        <div class="order_card_jiaobiao" v-else-if="showJiaoBiao && orderItem.statusName=='未中签'">
            <div class="order_card_jiaobiao_rect order_card_jiaobiao_rect_uncheck"></div>
            <div class="order_card_jiaobiao_desc">未中签</div>
        </div>
        <div class="order_card_jiaobiao" v-else-if="showJiaoBiao && orderItem.statusName=='进行中'">
            <div class="order_card_jiaobiao_rect order_card_jiaobiao_rect_processing"></div>
            <div class="order_card_jiaobiao_desc">进行中</div>
        </div>

<!--        公司&标题-->
        <div class="order_card_top">
            <div class="order_card_title">{{orderItem.enterpriseName}}</div>
            <div class="order_card_location">
                <div style="width: 12px;height: 12px;">
                    <img src="assets/location.png" style="width: 12px;height: 12px;">
                </div>
                <div class="order_card_location_path">{{orderItem.enterpriseAddress}}</div>
            </div>
        </div>
<!--        具体信息-->
        <div class="order_card_all_detail">
            <div class="order_card_detail">
                <span>订单号</span>
                <div class="order_card_detail_spilter">{{orderItem.orderNo}}</div>
                <div class="order_card_detail_spilter order_card_detail_spilter_right">{{orderItem.categoryName}}</div>
            </div>
            <div class="order_card_detail">
                <span>订单金额</span>
                <div>{{orderItem.orderAmount}}</div>
            </div>
            <div class="order_card_detail">
                <span>服务日期</span>
                <div>{{orderItem.serverTime}}</div>
            </div>
            <div class="order_card_detail">
                <span>订单时间</span>
                <div>{{orderItem.orderTime}}</div>
            </div>
        </div>
<!--        按钮-->
        <div class="order_card_tools">
            <slot name="tools" :data="orderItem"></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "OrderCard",
        props:{
            showJiaoBiao:Boolean,
            // jiaoBiaoType:Number, //1:已完成 2：未中签 3：进行中
            orderItem:Object,
        },
        data(){
            return{
                jiaobiao:[
                    "assets/order_jiaobiao_yiwancheng.png",
                ]
            }
        },
        mounted(){
            console.log(this.orderItem)
        },
        methods:{

        }
    }
</script>

<style scoped>
    .order_card{
        border-radius: 10px;
        background-color: white;
        width: 710px;
        margin-top: 30px;
        box-shadow: 0px 2px 20px rgba(100,100,100,0.5);
        position: relative;
        display: flex;
        flex-direction: column;
    }
    .order_card_title{
        font-size: 26px;
        letter-spacing: 2px;
        font-weight: bold;
        margin-top: 16px;
        margin-left: 30px;
    }
    .order_card_location{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 30px;
        font-size: 22px;
        color:#A79F9F;
        margin-top: 10px;
    }
    .order_card_location_path{
        margin-left: 10px;
    }

    .order_card_top{
        padding-bottom: 16px;
        border-bottom-color: #E4E4E4;
        border-bottom-width: 2px;
        border-bottom: 2px dashed #E4E4E4;
    }

    .order_card_all_detail{
        padding-bottom: 16px;
        border-bottom: 2px dashed #E4E4E4;
        letter-spacing: 2px;
    }

    .order_card_detail{
        margin-left: 30px;
        display: flex;
        flex-direction: row;
        margin-top: 16px;
        font-size: 26px;
        margin-right: 30px;
        line-height: 1;
        vertical-align: middle;
    }

    .order_card_detail span{
        display: block;
        color: #A79F9F;
        margin-right: 16px;
        width: 120px;
        letter-spacing: 2px;
        font-size: 26px;
    }
    .order_card_detail_spilter{
        flex:1 auto;
    }

    .order_card_detail_spilter_right{
        text-align: right;
    }

    .order_card_tools{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        margin-top:16px;
        margin-bottom: 16px;
        padding-right: 30px;
    }

    .order_card_tool_button{
        width: 120px;
        height: 56px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        letter-spacing: 2px;
        border-radius: 50px;
        margin-left: 24px;
    }

    .blue_button{
        background-color: #226DF8;
        color: white;
    }
    .gray_button{
        border:2px #D3D3D3 solid;
        color: #A79F9F;
    }

    .order_card_jiaobiao{
        position: absolute;
        right: 0;
        top:0;
        font-size: 26px;
        color: white;
        border-top-right-radius: 10px;
        width:136px;
        height: 108px;
        overflow: hidden;
    }

    .order_card_jiaobiao_rect{
        transform: rotateZ(40deg);
        background-color: #226DF8;
        height: 210px;
        width: 230px;
        margin-top: -114px;
        margin-left: 50px;
        margin-right: 0px;
        margin-bottom: 0px;

    }

    .order_card_jiaobiao_rect_uncheck{
        background-color:#535353 !important;
    }

    .order_card_jiaobiao_rect_processing{
        background-color: #3AA3D9 !important;
    }

    .order_card_jiaobiao_desc{
        position: absolute;
        transform: rotateZ(39deg);
        top:24px;
        left: 56px;
    }
</style>

<template>
<!--    订单状态-->
    <div class="order_list">
        <div v-show="loading"  style="text-align: center;font-size: 13px;width: 100%;height:26px;position: fixed;top:50%;z-index: 100;color: #D3D3D3">加载中...</div>

        <Navbar v-model="selected" class="order_navbar">
            <TabItem id="waite" class="order_tabitem">
                待上门
                <div :class="redPoint.one==1?'red':''"></div>
            </TabItem>
            <TabItem id="waite_confirm" class="order_tabitem">待确认
                <div :class="redPoint.two==1?'red':''"></div>
            </TabItem>
            <TabItem id="waite_finish" class="order_tabitem">待完成
                <div :class="redPoint.three==1?'red':''"></div>
            </TabItem>
            <TabItem id="all" class="order_tabitem">全部
                <div :class="redPoint.four==1?'red':''"></div>
            </TabItem>
        </Navbar>

        <TabContainer v-model="selected" class="order_tab_container" >

            <TabContainerItem id="waite" class="mint-tab-container-item-confirm xyg-order_tab_container_confirm-split">
                <div class="order_tab_container_confirm"
                     v-infinite-scroll="loadWaiteGoItems"
                     infinite-scroll-disabled="loading"
                     :infinite-scroll-immediate-check="true"
                     infinite-scroll-distance="10" >
                    <OrderCard v-for="(waiteItem,index) in waiteGoItems" :key="index" :orderItem="waiteItem">
                        <template  slot="tools" slot-scope="data">
                            <div class="order_card_tool_button blue_button" v-on:click="confirmShangmeng(data)">已上门</div>
                            <div class="order_card_tool_button gray_button" v-on:click="detail(data)">详情</div>
                        </template>
                    </OrderCard>
                    <div v-if="waiteGoItems.length==0 && !loading" class="none">
                        <img src="assets/none.png" />
                        <div>暂无内容</div>
                    </div>
                </div>
            </TabContainerItem>

            <TabContainerItem id="waite_confirm" class="mint-tab-container-item-confirm xyg-order_tab_container_confirm-split">
                <div class="order_tab_container_confirm"
                     v-infinite-scroll="waiteConfirmItems"
                     infinite-scroll-disabled="loading"
                     :infinite-scroll-immediate-check="true"
                     infinite-scroll-distance="10" >
                    <OrderCard  v-for="(waiteItem,index) in waiteConfirmItems" :key="index" :orderItem="waiteItem">
                        <template  slot="tools" slot-scope="data">
                            <div class="order_card_tool_button gray_button" v-on:click="detail(data)">详情</div>
                        </template>
                    </OrderCard>
                    <div v-if="waiteConfirmItems.length==0 && !loading" class="none">
                        <img src="assets/none.png" />
                        <div>暂无内容</div>
                    </div>
                </div>
            </TabContainerItem>

            <TabContainerItem id="waite_finish" class="mint-tab-container-item-confirm xyg-order_tab_container_confirm-split">
                <div class="order_tab_container_confirm"
                     v-infinite-scroll="waiteFinished"
                     infinite-scroll-disabled="loading"
                     :infinite-scroll-immediate-check="true"
                     infinite-scroll-distance="10"
                >
                    <OrderCard  v-for="(waiteItem,index) in waiteFinished" :key="index" :orderItem="waiteItem">
                        <template  slot="tools" slot-scope="data">
                            <div class="order_card_tool_button blue_button" v-on:click="finish(data)">完成</div>
                            <div class="order_card_tool_button gray_button" v-on:click="detail(data)">详情</div>
                        </template>
                    </OrderCard>
                    <div v-if="waiteFinished.length==0 && !loading" class="none">
                        <img src="assets/none.png" />
                        <div>暂无内容</div>
                    </div>
                </div>
            </TabContainerItem>

            <TabContainerItem id="all" class="mint-tab-container-item-confirm xyg-order_tab_container_confirm-split">
                <div class="order_tab_container_confirm"
                     v-infinite-scroll="all"
                     infinite-scroll-disabled="loading"
                     :infinite-scroll-immediate-check="true"
                     infinite-scroll-distance="10"
                >
                    <OrderCard  v-for="(waiteItem,index) in all"  :orderItem="waiteItem" :key="index"  :showJiaoBiao=true>
                        <template  slot="tools" slot-scope="data">
                            <div class="order_card_tool_button gray_button" v-on:click="detail(data)">详情</div>
                        </template>
                    </OrderCard>
                    <div v-if="all.length==0 && !loading" class="none">
                        <img src="assets/none.png" />
                        <div>暂无内容</div>
                    </div>
                </div>
            </TabContainerItem>
        </TabContainer>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { Toast } from 'mint-ui';
    import {Navbar,TabItem,TabContainer,TabContainerItem} from 'mint-ui';
    import OrderCard from "../../xyg/card/OrderCard";
    import OrderAction from "./OrderAction";
    import keys from "../../xyg/utils/keys";

    export default {
        name: "OrderList",
        props:{
            action:String,
        },
        components:{
            Navbar,
            TabItem,
            TabContainer,
            TabContainerItem,
            OrderCard
        },
        data(){
            return {
                selected:'waite',
                loading:false,
                waiteGoItems:[],
                waiteCurrentIdx:1,

                waiteConfirmItems:[],
                waiteConfirmIdx:1,

                waiteFinished:[],
                waiteFinishIdx:1,

                all:[],
                allIdx:1,

                redPoint:{one:0,two:0,three:0,four:0}
            }
        },
        watch:{
            selected:function (newVal,oldVal) {
                if(newVal=="waite") {
                    this.cancelRedPoint('one');
                    this.waiteGoItems=[];
                    this.waiteCurrentIdx=1;
                    this.loadWaiteGoItems();
                }
                if(newVal=="waite_confirm") {
                    this.cancelRedPoint('two');
                    this.waiteConfirmItems=[];
                    this.waiteConfirmIdx=1;
                    this.loadWaiteConfirm();
                }
                if(newVal=="waite_finish") {
                    this.cancelRedPoint('three');
                    this.waiteFinished=[];
                    this.waiteFinishIdx=1;
                    this.loadWaiteFinished();
                }
                if(newVal=="all") {
                    this.cancelRedPoint('four');
                    this.all=[];
                    this.allIdx=1;
                    this.loadAll();
                }
            },

            action:function (n,o) {
                if(n=='index_order'){
                    this.waiteGoItems=[];
                    this.waiteCurrentIdx=1;
                    this.init();
                }
            }
        },
        mounted(){
            this.init();
        },
        methods:{
            init(){
                this.initRedPoints();
                this.loadWaiteGoItems();
            },
            gotoService(){
                Toast({
                    message: '操作成功',
                    iconClass: 'mintui mintui-success',
                    duration: 1000
                });
            },
            confirmShangmeng(data){
                // this.$router.push({name:'JsOrderAction',params:{state:'待上门'}}).catch(e=>{});


                // // 第二种
                // this.registerComponent().then(component=>{
                //     let OrderActionExtend= new component({
                //         data:function () {
                //             return {
                //                 state:'待上门'
                //             }
                //         }
                //     })
                //     let control={name:'JsOrderAction',component: OrderActionExtend};
                //     this.$emit('showOrderAction',control);
                // });

                this.showPanel(data,"待上门");
            },
            finish(data){
                // this.$router.push({name:'JsOrderAction',params:{state:'待完成'}}).catch(e=>{});
                // this.registerComponent().then(component=>{
                //     let OrderActionExtend= new component({
                //         data:function () {
                //             return {
                //                 state:'待完成'
                //             }
                //         }
                //     })
                //     let control={name:'JsOrderAction',component: OrderActionExtend};
                //     this.$emit('showOrderAction',control);
                // });
                this.showPanel(data,"待完成");
            },
            detail(data){
                this.showPanel(data,"详情");
            },
            showPanel(data,type){
                //详情 执行
                // this.$router.push({name:'JsOrderAction',params:{state:'详情'}}).catch(e=>{});
                let detailUrl="/api/order/detail";
                let reqData={openId:localStorage.getItem(keys.openId),orderNo:data.data.orderNo};
                let _that=this;
                this.$post(detailUrl,reqData)
                    .then(rsp=>{
                        if(rsp.code==200 && rsp.success){
                            try{
                                let rspData=rsp.data;
                                console.log(rspData);
                                this.registerComponent().then(component=>{
                                    let OrderActionExtend= new component({
                                        data:function () {
                                            return {
                                                state:type,
                                                item:rspData,
                                                order:data.data.orderNo,
                                            }
                                        },
                                        methods: {
                                            reload(callback){
                                                callback();
                                                setTimeout(()=>{
                                                    //关闭窗口
                                                    _that.$emit('closeOrderAction',null);
                                                },200);
                                            },
                                            //待上门回调接口
                                            callback_loadWaiteGoItems(){
                                                _that.waiteCurrentIdx=1;
                                                _that.waiteGoItems=[];
                                                _that.initRedPoints();
                                                _that.loadWaiteGoItems();
                                            },
                                            //待完成回调接口
                                            callback_loadWaiteFinished(){
                                                _that.waiteFinishIdx=1;
                                                _that.waiteFinished=[];
                                                _that.initRedPoints();
                                                _that.loadWaiteFinished();
                                            }
                                        }
                                    })
                                    let control={name:'JsOrderAction',component: OrderActionExtend};
                                    this.$emit('showOrderAction',control);
                                });
                            }
                            catch (e) {
                                console.log(e);
                            }
                        }
                    })
                    .catch(e=>{
                        console.log(e);
                    });
            },
            registerComponent() { //注册操作
                return import(`./OrderAction.vue`).then(component => {
                    return Vue.extend(component.default);
                });
            },
            loadWaiteGoItems(){
                if(this.loading) return;
                this.loading=true;
                let url="/api/order/page";
                let data={status:5000,openId:localStorage.getItem(keys.openId),size:10,current:this.waiteCurrentIdx};
                setTimeout(()=>{
                    this.$post(url,data)
                        .then(rsp=>{
                            console.log(rsp);
                            this.loading=false;
                            if(rsp.code==200 && rsp.success){
                                try{
                                    let rows=rsp.data.rows;
                                    if(rows!=null && rows.length>0){
                                        this.waiteCurrentIdx=this.waiteCurrentIdx+1;
                                        rows.forEach(t=>{
                                            this.waiteGoItems.push(t);
                                        })
                                    }
                                }
                                catch (e) {
                                    console.log(e);
                                }
                            }
                        })
                        .catch(e=>{
                            console.log('error=====>'+e)
                            this.loading=false;
                        });
                    this.loading=false;
                },50);

             },
            loadWaiteConfirm(){
                if(this.loading) return;
                this.loading=true;
                let url="/api/order/page";
                let data={status:5001,openId:localStorage.getItem(keys.openId),size:10,current:this.waiteConfirmIdx};
                setTimeout(()=>{
                    this.$post(url,data)
                        .then(rsp=>{
                            console.log("待确认");
                            this.loading=false;
                            if(rsp.code==200 && rsp.success){
                                try{
                                    let rows=rsp.data.rows;
                                    if(rows!=null && rows.length>0){
                                        this.waiteConfirmIdx=this.waiteConfirmIdx+1;
                                        rows.forEach(t=>{
                                            this.waiteConfirmItems.push(t);
                                        })
                                    }
                                }
                                catch (e) {
                                    console.log(e);
                                }
                            }
                        })
                        .catch(e=>{
                            console.log(e)
                            this.loading=false;
                        });
                    this.loading=false;
                },50);
            },
            loadWaiteFinished(){
                if(this.loading) return;
                this.loading=true;
                let url="/api/order/page";
                let data={status:6000,openId:localStorage.getItem(keys.openId),size:10,current:this.waiteFinishIdx};
                setTimeout(()=>{
                    this.$post(url,data)
                        .then(rsp=>{
                            console.log("待完成");
                            this.loading=false;
                            if(rsp.code==200 && rsp.success){
                                try{
                                    let rows=rsp.data.rows;
                                    if(rows!=null && rows.length>0){
                                        this.waiteFinishIdx=this.waiteFinishIdx+1;
                                        rows.forEach(t=>{
                                            this.waiteFinished.push(t);
                                        })
                                    }
                                }
                                catch (e) {
                                    console.log(e);
                                }
                            }
                        })
                        .catch(e=>{
                            console.log(e)
                            this.loading=false;
                        });
                    this.loading=false;
                },50);
            },
            loadAll(){
                if(this.loading) return;
                this.loading=true;
                let url="/api/order/page";
                let data={openId:localStorage.getItem(keys.openId),size:10,current:this.allIdx};
                setTimeout(()=>{
                    this.$post(url,data)
                        .then(rsp=>{
                            console.log("所有订单");
                            this.loading=false;
                            if(rsp.code==200 && rsp.success){
                                try{
                                    let rows=rsp.data.rows;
                                    if(rows!=null && rows.length>0){
                                        this.allIdx=this.allIdx+1;
                                        rows.forEach(t=>{
                                            this.all.push(t);
                                        })
                                    }
                                }
                                catch (e) {
                                    console.log(e);
                                }
                            }
                        })
                        .catch(e=>{
                            console.log(e)
                            this.loading=false;
                        });
                },50);
            },
            resetPageIndex(name){
                this.selected=name;
            },
            initRedPoints(){
                let url="/api/point/getPoint";
                let data={
                    openId: localStorage.getItem(keys.openId),
                    type: ""
                };
                this.$post(url,data)
                    .then(rsp=>{
                        if(rsp.code==200 && rsp.success){
                            let data=rsp.data;
                            let one=data.one;
                            let two=data.two;
                            let three=data.three;
                            let four=data.four;
                            this.$set(this.redPoint,'one',one);
                            this.$set(this.redPoint,'two',two);
                            this.$set(this.redPoint,'three',three);
                            this.$set(this.redPoint,'four',four);
                        }
                    })
                    .catch(e=>{
                        console.log(e);
                    })

            },
            cancelRedPoint(idx){
                let url="/api/point/clearPoint";
                let data={
                    openId: localStorage.getItem(keys.openId),
                    type: "",
                    selected:idx
                };
                this.$post(url,data)
                    .then(rsp=>{
                        if(rsp.code==200 && rsp.success){
                            this.$set(this.redPoint,idx,0);
                        }
                    })
                    .catch(e=>{
                        console.log(e);
                    })

            }
         }
    }
</script>

<style scoped>
    .order_list{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;
        height: 100vh;
    }

    .order_navbar{
        width: 710px;
        border-radius: 10px;
        height: 100px;
        z-index: 10;
        margin-top: 20px;
    }

    .order_tab_container{
        flex:1;
        overflow: hidden !important;
        width:750px;
    }

    .order_tabitem{
        padding:unset !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 28px !important;
        color: #A79F9F !important;
        position: relative;
    }

    .order_tabitem .red{
        position: absolute;
        background-color: red;
        width: 20px;
        height: 20px;
        border-radius: 20px;
        top:10px;
        right: 10px;
    }


    .is-selected{
        color:white !important;
        background-color: #226DF8;
        border-bottom:none !important;
        margin:unset !important;
        font-weight: bold;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .mint-tab-container-item-confirm{
        height: 100vh;
        overflow: scroll;
    }

    .order_tab_container_confirm{
        flex: 1;
        padding-bottom: 280px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .xyg-order_tab_container_confirm-split{
        margin-top: 1px;
    }

    .order_tab_container_confirm::-webkit-scrollbar{
        display: none;
    }

    .none{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #666666;
        font-size: 28px;
        margin-top: 220px;
    }

    .none img{
        width: 200px;
        height: 200px;
    }


</style>

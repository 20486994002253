<template>
    <div class="tabbar-bottom" @click="onSelected">
        <slot name="icon" :url="this.icons[this.idx]"></slot>
        <div :class="this.idx==0?'txt1':'txt0'">{{ this.showTxt }}</div>
    </div>
</template>

<script>
    export default {
        name: "tabbarItem",
        props:{
            showTxt:String,
            icons:Array,
            name:String,
            idx:Number,
        },
        data(){
            return {
                url:this.icons[this.idx],
            };
        },
        computed:{

        },
        created(){
        },
        methods:{
            onSelected(){
                this.$emit('currentPageIndex',this.name);
            }
        }
    }
</script>

<style scoped>
    .tabbar-bottom{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex:1 1 auto;
    }

    .tabbar-bottom .txt1{
        font-size: 26px;
        color: black;
    }

    .tabbar-bottom .txt0{
        font-size: 26px;
        color:#226DF8;
    }
</style>

const tools={
    StringIsBank(val){
        if(val==undefined || val==null || val=="" || val.length==0) return true;
        return false;
    },

    QueryString(key){
        var query=location.search.substring(1);
        var vars = query.split("&");
        for (var i=0;i<vars.length;i++) {
            var pair = vars[i].split("=");
            if(pair[0] == key){return pair[1];}
        }
        return "";
    }
}

export default tools;

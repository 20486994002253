<template>
    <div class="order_card">
        <!--        角标-->
        <div class="order_card_jiaobiao" v-if="showJiaoBiao && changeJiaoBiao==1">
            <div class="order_card_jiaobiao_rect"></div>
            <div class="order_card_jiaobiao_desc">已完成</div>
        </div>
        <div class="order_card_jiaobiao" v-else-if="showJiaoBiao && changeJiaoBiao==2">
            <div class="order_card_jiaobiao_rect order_card_jiaobiao_rect_uncheck"></div>
            <div class="order_card_jiaobiao_desc">待审核</div>
        </div>
        <div class="order_card_jiaobiao" v-else-if="showJiaoBiao && changeJiaoBiao==3">
            <div class="order_card_jiaobiao_rect order_card_jiaobiao_rect_processing"></div>
            <div class="order_card_jiaobiao_desc">进行中</div>
        </div>

        <!--        公司&标题-->
        <div class="order_card_top" :class="showJiaoBiao==true?'order_card_time_extend_change':''">
            <div class="order_card_title">【{{ reqItem.categoryName }}】</div>
            <div class="order_card_time" v-if="showJiaoBiao==false">{{reqItem.createTime}}</div>
            <div class="order_card_time_extend" v-else>
                <img src="assets/icon_time.png" />
                {{reqItem.createTime}}
            </div>
        </div>
        <!--        具体信息-->
        <div class="order_card_all_detail">
            <div class="order_card_detail">
                <span>服务日期</span>
                <div>{{reqItem.time}}</div>
            </div>
            <div class="order_card_detail">
                <img v-for="(pic,index) in reqItem.pictures" :key="'img'+index" :src="pic" />
<!--                <img src="assets/demo_i1.png"/>-->
<!--                <img src="assets/demo_i2.png"/>-->
<!--                <img src="assets/demo_i3.png"/>-->
            </div>
            <div class="order_card_detail">
                <span>预算金额</span>
                <div>{{reqItem.perAmount}}</div>
            </div>
            <div class="order_card_detail order_card_detail_dingbiaoinfo" v-if="showJingBiao && reqItem.campaignVo!=null && reqItem.campaignVo!=undefined">
                <div class="labelinfo">定标人信息</div>
                <div class="order_card_headerImg">
                    <img :src="reqItem.campaignVo.avatar"/>
                    <div class="peoplesinfo">
                        <div class="p0 p1">{{ reqItem.campaignVo.name }}</div>
                        <div class="p0 p2">{{ reqItem.campaignVo.idCard }}</div>
                        <div class="p3">{{ reqItem.campaignVo.profession }}</div>
                    </div>
                </div>
            </div>
        </div>
        <!--        按钮-->
        <div class="order_card_tools">
            <slot name="tools" :data="reqItem"></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CompanyOrderCard",
        props:{
            showJiaoBiao:Boolean,
            showJingBiao:Boolean,
            reqItem:Object,
        },
        data(){
            return{
                jiaobiao:[
                    "assets/order_jiaobiao_yiwancheng.png",

                ],

            }
        },
        computed:{
            changeJiaoBiao(){
                if(this.reqItem.stateName=='待审核'){
                    return 2;
                }
                if(this.reqItem.stateName=='已完成'){
                    return 1;
                }
                if(this.reqItem.stateName=='进行中'){
                    return 3;
                }
                return 0;
            }
        },
        mounted(){
            console.log(this.reqItem);
        },
        methods:{

        }
    }
</script>

<style scoped>
    .order_card{
        border-radius: 10px;
        background-color: white;
        width: 710px;
        margin-top: 30px;
        box-shadow: 0px 2px 20px rgba(100,100,100,0.5);
        position: relative;
        display: flex;
        flex-direction: column;
    }
    .order_card_title{
        font-size: 26px;
        letter-spacing: 2px;
        font-weight: bold;
        margin-top: 16px;
        margin-left: 30px;
        flex:1;
    }
    .order_card_time{
        margin-right: 30px;
        font-size: 26px;
        margin-top: 16px;
    }

    .order_card_time_extend{
        font-size: 22px;
        letter-spacing: 2px;
        color: #A79F9F;
        margin-left: 30px;
        margin-top: 8px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .order_card_time_extend img{
        width: 24px;
        height: 24px;
        margin-right: 10px;
    }

    .order_card_top{
        padding-bottom: 16px;
        border-bottom-color: #E4E4E4;
        border-bottom-width: 2px;
        border-bottom: 2px dashed #E4E4E4;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .order_card_all_detail{
        padding-bottom: 16px;
        border-bottom: 2px dashed #E4E4E4;
        letter-spacing: 2px;
    }

    .order_card_detail{
        margin-left: 30px;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 16px;
        font-size: 26px;
        margin-right: 30px;
        line-height: 1;
        vertical-align: middle;
    }

    .order_card_detail span{
        display: block;
        color: #A79F9F;
        margin-right: 16px;
        width: 120px;
        letter-spacing: 2px;
        font-size: 26px;
    }

    .order_card_detail img{
        width: 150px;
        height: 150px;
        margin-right: 20px;
    }

    .order_card_detail .order_card_headerImg{
        display: flex;
        flex-direction: row;
        margin-top: 16px;
    }
    .order_card_detail .order_card_headerImg img{
        width: 60px;
        height: 60px;
        border-radius: 60px;
        margin-right: 0 !important;
    }

    .order_card_detail .order_card_headerImg .peoplesinfo{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 30px;
    }
    .order_card_detail .order_card_headerImg .peoplesinfo .p0{
        margin-bottom: 16px;
    }

    .order_card_detail_dingbiaoinfo{
        display: flex !important;
        flex-direction: column !important;
        align-items:unset !important;

        width:160px !important;
        color: #A79F9F;
        font-size: 26px;
    }

    .order_card_detail_spilter{
        flex:1 auto;
    }

    .order_card_detail_spilter_right{
        text-align: right;
    }

    .order_card_tools{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        margin-top:16px;
        margin-bottom: 16px;
        padding-right: 30px;
    }

    .order_card_tool_button{
        width: 120px;
        height: 56px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        letter-spacing: 2px;
        border-radius: 50px;
        margin-left: 24px;
    }

    .blue_button{
        background-color: #226DF8;
        color: white;
    }

    .red_button{
        background-color: #9C0000;
        color: white;
    }

    .blue_button_extend{
        width: unset !important;
        padding-left: 22px;
        padding-right: 22px;
    }

    .gray_button{
        border:2px #D3D3D3 solid;
        color: #A79F9F;
    }

    .order_card_jiaobiao{
        position: absolute;
        right: 0;
        top:0;
        font-size: 26px;
        color: white;
        border-top-right-radius: 10px;
        width:136px;
        height: 108px;
        overflow: hidden;
    }

    .order_card_jiaobiao_rect{
        transform: rotateZ(40deg);
        background-color: #226DF8;
        height: 210px;
        width: 230px;
        margin-top: -114px;
        margin-left: 50px;
        margin-right: 0px;
        margin-bottom: 0px;

    }

    .order_card_jiaobiao_rect_uncheck{
        background-color:#535353 !important;
    }

    .order_card_jiaobiao_rect_processing{
        background-color: #3AA3D9 !important;
    }

    .order_card_jiaobiao_desc{
        position: absolute;
        transform: rotateZ(39deg);
        top:24px;
        left: 56px;
    }

    .order_card_time_extend_change{
        flex-direction: column !important;
        align-items: flex-start;
    }
</style>

<template>
<!--    订单状态-->
    <div class="order_list">
        <div v-show="loading"  style="text-align: center;font-size: 13px;width: 100%;height:26px;position: fixed;top:50%;z-index: 100;color: #D3D3D3">加载中...</div>
        <Navbar v-model="selected" class="order_navbar">
            <TabItem id="confirm" class="order_tabitem">已审核
                <div :class="redPoint.one==1?'red':''" />
            </TabItem>
            <TabItem id="waite" class="order_tabitem">已定标
                <div :class="redPoint.two==1?'red':''" />
            </TabItem>
            <TabItem id="waite_finish" class="order_tabitem">待服务
                <div :class="redPoint.three==1?'red':''" />
            </TabItem>
            <TabItem id="all" class="order_tabitem">全部
                <div :class="redPoint.four==1?'red':''" />
            </TabItem>
        </Navbar>

        <TabContainer v-model="selected" class="order_tab_container" >
            <TabContainerItem id="confirm" class="mint-tab-container-item-confirm xyg-order_tab_container_confirm-split">
                <div class="order_tab_container_confirm"
                     v-infinite-scroll="loadChecked"
                     infinite-scroll-disabled="loading"
                     :infinite-scroll-immediate-check="true"
                     infinite-scroll-distance="10"
                >
                    <CompanyOrderCard :showJingBiao=false v-for="(item,index) in checkDataList" :key="'coc'+index" :reqItem="item">
                        <template  slot="tools" slot-scope="data">
                            <div class="order_card_tool_button gray_button" v-on:click="toDetail(data)">详情</div>
                        </template>
                    </CompanyOrderCard>
                    <div v-if="checkDataList.length==0 && !loading" class="none">
                        <img src="assets/none.png" />
                        <div>暂无内容</div>
                    </div>
                </div>

            </TabContainerItem>

            <TabContainerItem id="waite" class="mint-tab-container-item-confirm xyg-order_tab_container_confirm-split">
                <div class="order_tab_container_confirm"
                     v-infinite-scroll="loadWaite"
                     infinite-scroll-disabled="loading"
                     :infinite-scroll-immediate-check="true"
                     infinite-scroll-distance="10"
                >
                    <CompanyOrderCard :showJingBiao=true v-for="(waiteItem,index) in confirmDataList" :key="'waite'+index" :reqItem="waiteItem">
                        <template slot="tools" slot-scope="data">
                            <div class="order_card_tool_button blue_button blue_button_extend" v-on:click="toPay(data)">上传支付凭证</div>
                            <div class="order_card_tool_button gray_button" v-on:click="toDetail(data)">详情</div>
                        </template>
                    </CompanyOrderCard>
                    <div v-if="confirmDataList.length==0 && !loading" class="none">
                        <img src="assets/none.png" />
                        <div>暂无内容</div>
                    </div>
                </div>
            </TabContainerItem>

            <TabContainerItem id="waite_finish" class="mint-tab-container-item-confirm xyg-order_tab_container_confirm-split">
                <div class="order_tab_container_confirm"
                     v-infinite-scroll="loadFinishing"
                     infinite-scroll-disabled="loading"
                     :infinite-scroll-immediate-check="true"
                     infinite-scroll-distance="10"
                >
                    <CompanyOrderCard :showJingBiao=true v-for="(finishItem,index) in finishingDataList" :key="'finishItem'+index" :reqItem="finishItem">
                        <template slot="tools" slot-scope="data">
                            <div class="order_card_tool_button blue_button blue_button_extend" v-if="finishItem.state==3001" v-on:click="confirmComeIn(data)">确认上门</div>
                            <div class="order_card_tool_button blue_button blue_button_extend" v-if="finishItem.state==3002" v-on:click="confrimFinished(data)">确认完成</div>
                            <div class="order_card_tool_button red_button blue_button_extend" v-if="finishItem.state==3002" @click="showUnAgree(data)">异议</div>
                            <div class="order_card_tool_button gray_button" v-on:click="toDetail(data)">详情</div>
                        </template>
                    </CompanyOrderCard>

                    <div v-if="finishingDataList.length==0 && !loading" class="none">
                        <img src="assets/none.png" />
                        <div>暂无内容</div>
                    </div>

<!--                    <CompanyOrderCard :showJingBiao=true>-->
<!--                        <template slot="tools">-->
<!--                            <div class="order_card_tool_button blue_button blue_button_extend" v-on:click="confirmComeIn">确认上门</div>-->
<!--                            <div class="order_card_tool_button blue_button blue_button_extend" v-on:click="confrimFinished">确认完成</div>-->
<!--                            <div class="order_card_tool_button red_button blue_button_extend">异议</div>-->
<!--                            <div class="order_card_tool_button gray_button" v-on:click="toDetail">详情</div>-->
<!--                        </template>-->
<!--                    </CompanyOrderCard>-->
                </div>
            </TabContainerItem>

            <TabContainerItem id="all" class="mint-tab-container-item-confirm xyg-order_tab_container_confirm-split">
                <div class="order_tab_container_confirm">
                    <CompanyOrderCard :showJiaoBiao=true v-for="(allitem,index) in allDataList" :key="'all'+index" :reqItem="allitem" :showJingBiao=true>
                        <template  slot="tools" slot-scope="data">
                            <div class="order_card_tool_button gray_button" v-on:click="toDetail(data)">详情</div>
                        </template>
                    </CompanyOrderCard>
                    <div v-if="allDataList.length==0 && !loading" class="none">
                        <img src="assets/none.png" />
                        <div>暂无内容</div>
                    </div>
                </div>
            </TabContainerItem>
        </TabContainer>
    </div>
</template>

<script>
    import Vue from 'vue';
    import {Navbar,TabItem,TabContainer,TabContainerItem,Toast,MessageBox} from 'mint-ui';
    import CompanyOrderCard from "../../xyg/card/CompanyOrderCard";
    import keys from "../../xyg/utils/keys";

    export default {
        name: "OrderList",
        components:{
            Navbar,
            TabItem,
            TabContainer,
            TabContainerItem,
            CompanyOrderCard
        },
        props:{
            action:String,
        },
        data(){
            return {
                selected:'confirm',
                loading:false,
                checkDataList:[],
                currentCheckedIdx:1,

                confirmDataList:[],
                currentConfirmIdx:1,

                finishingDataList:[],
                currentFinishingIdx:1,

                allDataList:[],
                allIdx:1,

                redPoint:{one:0,two:0,three:0,four:0}
            }
        },
        mounted(){
            this.init();
        },
        watch:{
            selected:function (nV,oV) {
                if(nV=='confirm') {
                    this.cancelRedPoint('one');
                    this.checkDataList=[];
                    this.currentCheckedIdx=1;
                    this.loadChecked();
                }
                if(nV=='waite') {
                    this.cancelRedPoint('two');
                    this.confirmDataList=[];
                    this.currentConfirmIdx=1;
                    this.loadWaite();
                }
                if(nV=='waite_finish') {
                    this.cancelRedPoint('three');
                    this.finishingDataList=[];
                    this.currentFinishingIdx=1;
                    this.loadFinishing();
                }
                if(nV=='all') {
                    this.cancelRedPoint('four');
                    this.allDataList=[];
                    this.allIdx=1;
                    this.loadAll();
                }
            },
            action:function (n,o) {
                if(n=='index_order'){
                    this.checkDataList=[];
                    this.currentCheckedIdx=1;
                    this.init();
                }
            }
        },
        methods:{
            init(){
                this.initRedPoints();
                this.loadChecked();
            },
            initRedPoints(){
                let url="/api/point/getPoint";
                let data={
                    openId: localStorage.getItem(keys.openId),
                    type: ""
                };
                this.$post(url,data)
                    .then(rsp=>{
                        if(rsp.code==200 && rsp.success){
                            let data=rsp.data;
                            let one=data.one;
                            let two=data.two;
                            let three=data.three;
                            let four=data.four;
                            this.$set(this.redPoint,'one',one);
                            this.$set(this.redPoint,'two',two);
                            this.$set(this.redPoint,'three',three);
                            this.$set(this.redPoint,'four',four);
                        }
                    })
                    .catch(e=>{
                        console.log(e);
                    })

            },
            toDetail(data){
                console.log('reqlist_detail_data',data.data);
                let path=`./OrderAction.vue`;
                // 第二种
                this.registerComponent(path).then(component=>{
                    let OrderActionExtend= new component({
                        data:function () {
                            return {
                                state:'待上门',
                                item:data.data,
                            }
                        }
                    })
                    let control={name:'GsOrderAction',component: OrderActionExtend};
                    this.$emit('showGsOrderAction',control);
                });
            },
            toPay(data){
                let path=`./OrderAction.vue`;
                let _that=this;
                this.registerComponent(path).then(component=>{
                    let OrderActionExtend=new component({
                        data:function () {
                            return {
                                state:'去支付',
                                item:data.data,
                            }
                        },
                        methods:{
                            reload(callback){
                                callback();
                                setTimeout(()=>{
                                    //关闭窗口
                                    _that.$emit('closeOrderAction',null);
                                },200);
                            },
                            callback_loadConfirm(){
                                _that.confirmDataList=[];
                                _that.currentConfirmIdx=1;
                                _that.initRedPoints();
                                _that.loadWaite();
                            },
                        }
                    });

                    let control={name:'GsOrderAction',component: OrderActionExtend};
                    this.$emit('showGsOrderAction',control);

                });
            },
            loadFinishing(){
                if(this.loading) return;
                this.loading=true;
                let openId=localStorage.getItem(keys.openId);
                let category=3999;
                let data={openId:openId,state:category,size:10,current:this.currentFinishingIdx};
                this.$post('/api/product/list',data)
                    .then(rsp=>{
                        if(rsp.code==200 && rsp.success){
                            let rows=rsp.data.rows;
                            if(rows!=null && rows.length>0){
                                this.currentFinishingIdx=this.currentFinishingIdx+1;
                                rows.forEach(t=>{
                                    this.finishingDataList.push(t);
                                })
                            }
                        }
                        this.loading=false;
                    })
                    .catch(e=>{
                        console.log(e);
                    });
            },
            loadWaite(){ //加载已定标数据
                if(this.loading) return;
                this.loading=true;
                let openId=localStorage.getItem(keys.openId);
                let category=2000;
                let data={openId:openId,state:category,size:10,current:this.currentConfirmIdx};
                this.$post('/api/product/list',data)
                    .then(rsp=>{
                        if(rsp.code==200 && rsp.success){
                            let rows=rsp.data.rows;
                            if(rows!=null && rows.length>0){
                                this.currentConfirmIdx=this.currentConfirmIdx+1;
                                rows.forEach(t=>{
                                    this.confirmDataList.push(t);
                                })
                            }
                        }
                        this.loading=false;
                    })
                    .catch(e=>{
                        console.log(e);
                    });
            },
            loadChecked(){ //加载已审核数据
                if(this.loading) return;
                this.loading=true;
                let openId=localStorage.getItem(keys.openId);
                let category=1001;
                let data={openId:openId,state:category,size:10,current:this.currentCheckedIdx};
                this.$post('/api/product/list',data)
                    .then(rsp=>{
                        if(rsp.code==200 && rsp.success){
                            let rows=rsp.data.rows;
                            if(rows!=null && rows.length>0){
                                this.currentCheckedIdx=this.currentCheckedIdx+1;
                                rows.forEach(t=>{
                                    this.checkDataList.push(t);
                                })
                            }
                        }
                        this.loading=false;
                    })
                    .catch(e=>{
                        console.log(e);
                    });
            },
            loadAll(){
                if(this.loading) return;
                this.loading=true;
                let openId=localStorage.getItem(keys.openId);
                let data={openId:openId,state:0,size:10,current:this.allIdx};
                this.$post('/api/product/list',data)
                    .then(rsp=>{
                        if(rsp.code==200 && rsp.success){
                            let rows=rsp.data.rows;
                            if(rows!=null && rows.length>0){
                                this.allIdx=this.allIdx+1;
                                rows.forEach(t=>{
                                    this.allDataList.push(t);
                                })
                            }
                        }
                        this.loading=false;
                    })
                    .catch(e=>{
                        console.log(e);
                    });
            },
            confirmComeIn(data){ //确认上门
                MessageBox({
                    message:'确认技师已上门服务吗？',
                    titile:'提示',
                    showCancelButton:true,
                    confirmButtonText:'确定',
                    cancelButtonText:'取消'
                }).then(action=>{
                    if(action==='confirm'){
                        let openId=localStorage.getItem(keys.openId);
                        let pid=data.data.id;
                        this.$post('/api/product/confirmArrival',{
                            openId:openId,
                            pid:pid
                        }).then(rsp=>{
                            if(rsp.code==200 && rsp.success){
                                //确认上门
                                Toast({
                                    message: '确认已上门',
                                    iconClass: 'mintui mintui-success',
                                    duration: 1000
                                });
                                this.finishingDataList=[];
                                this.currentFinishingIdx=1;
                                this.initRedPoints();
                                this.loadFinishing();
                            }else{
                                //确认上门
                                Toast({
                                    message: rsp.message,
                                    iconClass: 'mintui mintui-success',
                                    duration: 1000
                                });
                            }
                        })
                            .catch(e=>{
                                console.log(e);
                            })

                    }
                }).catch(e=>{

                });
            },
            confrimFinished(data){
                MessageBox({
                    message:'确认技师已完成服务吗？',
                    titile:'提示',
                    showCancelButton:true,
                    confirmButtonText:'确定',
                    cancelButtonText:'取消'
                }).then(action=>{
                    if(action==='confirm'){
                        let openId=localStorage.getItem(keys.openId);
                        let pId=data.data.id;
                        console.log('pId',pId);
                        this.$post('/api/product/confirmComplete',{
                            openId:openId,
                            pid:pId
                        }).then(rsp=>{
                            if(rsp.code==200 && rsp.success){
                                //确认上门
                                Toast({
                                    message: '确认完成',
                                    iconClass: 'mintui mintui-success',
                                    duration: 1000
                                });
                                this.finishingDataList=[];
                                this.currentFinishingIdx=1;
                                this.initRedPoints();
                                this.loadFinishing();
                            }else {
                                //确认上门
                                Toast({
                                    message: rsp.message,
                                    iconClass: 'mintui mintui-success',
                                    duration: 1000
                                });
                            }
                        })
                            .catch(e=>{
                                console.log(e);
                            });
                    }
                }).catch(e=>{

                });
            },
            registerComponent(path) { //注册操作
                return import(`${path}`).then(component => {
                    return Vue.extend(component.default);
                });
            },
            showUnAgree(data){
                MessageBox({
                    message:'确定存在异议？',
                    titile:'提示',
                    showCancelButton:true,
                    confirmButtonText:'确定',
                    cancelButtonText:'取消'
                }).then(action=>{
                    if(action=='confirm'){
                        let path=`./OrderAction.vue`;
                        // 第二种

                        let _that=this;
                        this.registerComponent(path).then(component=>{
                            let OrderActionExtend= new component({
                                data:function () {
                                    return {
                                        state:'异议',
                                        item:data.data,
                                    }
                                },
                                methods:{
                                    reload(callback){
                                        callback();
                                        setTimeout(()=>{
                                            //关闭窗口
                                            _that.$emit('closeOrderAction',null);
                                        },200);
                                    },
                                    callback_loadFinishing(){
                                        _that.finishingDataList=[];
                                        _that.currentFinishingIdx=1;
                                        _that.initRedPoints();
                                        _that.loadFinishing();
                                    },
                                }
                            })
                            let control={name:'GsOrderAction',component: OrderActionExtend};
                            this.$emit('showGsOrderAction',control);
                        });
                    }
                })
                    .catch(e=>{})
            },
            resetPageIndex(name){
                this.selected=name;
            },
            cancelRedPoint(idx){
                let url="/api/point/clearPoint";
                let data={
                    openId: localStorage.getItem(keys.openId),
                    type: "",
                    selected:idx
                };
                this.$post(url,data)
                    .then(rsp=>{
                        if(rsp.code==200 && rsp.success){
                            this.$set(this.redPoint,idx,0);
                        }
                    })
                    .catch(e=>{
                        console.log(e);
                    })

            }
        }
    }
</script>

<style scoped>
    .order_list{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;
        height: 100vh;
    }

    .order_navbar{
        width: 710px;
        border-radius: 10px;
        height: 100px;
        z-index: 10;
        margin-top: 20px;
    }

    .order_tab_container{
        flex:1;
        overflow: hidden !important;
        width:750px;
    }

    .order_tabitem{
        padding:unset !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 28px !important;
        color: #A79F9F !important;
        position: relative;
    }

    .order_tabitem .red{
        position: absolute;
        background-color: red;
        width: 20px;
        height: 20px;
        border-radius: 20px;
        top:10px;
        right: 10px;
    }

    .is-selected{
        color:white !important;
        background-color: #226DF8;
        border-bottom:none !important;
        margin:unset !important;
        font-weight: bold;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .mint-tab-container-item-confirm{
        height: 100vh;
        overflow: scroll;
    }

    .order_tab_container_confirm{
        flex: 1;
        padding-bottom: 280px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .xyg-order_tab_container_confirm-split{
        margin-top: 1px;
    }

    .order_tab_container_confirm::-webkit-scrollbar{
        display: none;
    }

    .none{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #666666;
        font-size: 28px;
        margin-top: 220px;
    }

    .none img{
        width: 200px;
        height: 200px;
    }
</style>

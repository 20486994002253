<template>
    <div class="ordershangmeng">
<!--        第一个卡片-->
        <div class="ordershangmeng_card">
            <div class="ordershangmeng_card_header">
                <div class="p1">{{item.name}}</div>
                <div class="p2">
                    <img src="assets/order_card_location.png"/>
                    <div class="location">{{item.address}}</div>
                </div>
            </div>

            <div class="ordershangmeng_card_content">
                <div class="item">
                    <div class="p1">服务日期</div>
                    <div class="p2">{{item.time}}</div>
                    <div class="p3">{{item.categoryName}}</div>
                </div>
                <div class="item" v-if="item.pictures.length>0">
                    <img  v-for="(imgItem,index) in item.pictures" :key="index" :src="imgItem" @click="showImg(imgItem,item.pictures)"/>
<!--                    <img src="assets/demo_i1.png"/>-->
<!--                    <img src="assets/demo_i2.png"/>-->
<!--                    <img src="assets/demo_i3.png"/>-->
                </div>
                <div class="item remark">
                    <div>投标说明&要求</div>
                    <div class="p4">
                        <div v-html="info"></div>
                    </div>
                </div>
            </div>
        </div>
<!--        第二个卡片-->
<!--        待上门-->
        <div class="ordershangmeng_card title bottom" v-if="state=='待上门'">
            <div class="ordershangmeng_card_info">提交上门信息</div>
            <div class="ordershangmeng_card_imgs">
                <div style="position:relative" v-for="(imgFile,index) in signViews" :key="'sign'+index" >
                    <div class="close">
                        <img src="assets/imgClose.png" @click="removeSignView(index)" />
                    </div>
                    <img :src="imgFile"  @click="showImg(imgFile,signViews)" />
                </div>

                <input type="file" accept="image/jpeg,image/png" multiple="multiple" ref="fileUpload" style="display: none;"/>
                <img src="assets/fileupload.png" @click="chooseImage" v-if="signFile.length<3"/>
            </div>
            <div class="ordershangmeng_card_tools">
                <div class="button" @click="confirmSign">确认</div>
            </div>
        </div>

<!--        待完成-->
        <div class="ordershangmeng_card title bottom" v-else-if="state=='待完成'">
            <div class="ordershangmeng_card_info">提交完成信息</div>
            <div class="ordershangmeng_card_imgs">
                <div style="position:relative"  v-for="(imgFile,index) in finishedView" :key="'sign'+index" >
                    <div class="close">
                        <img src="assets/imgClose.png" @click="removeFinishView(index)" />
                    </div>
                    <img :src="imgFile"  @click="showImg(imgFile,finishedView)" />
                </div>

                <input type="file" accept="image/jpeg,image/png" multiple="multiple" ref="uploadFinished" style="display: none;"/>
                <img src="assets/fileupload.png" @click="chooseFinishedImage" v-if="finishedFile.length<3"/>
            </div>
            <div class="ordershangmeng_card_tools">
                <div class="button" @click="confirmFinished">确认</div>
            </div>
        </div>

<!--        详情-->
        <div class="ordershangmeng_card title bottom" v-else-if="state=='详情'">
            <div class="ordershangmeng_card_info">结果说明</div>
            <div class="detail_result" :class="item.success?'':'untag'">
                {{item.successName}}
            </div>
            <div class="detail_remark" v-html="detailRemark">

            </div>
        </div>

<!--        签到信息-->
        <div class="ordershangmeng_card title bottom" v-if="item.signPicList!=null && item.signPicList.length>0">
            <div class="ordershangmeng_card_info">签到信息</div>
            <div class="ordershangmeng_card_imgs margin_bottom_30">
                <img v-for="(imgFile,index) in item.signPicList" :key="'signinfo'+index" :src="imgFile" />
            </div>
        </div>

        <!--        完成信息-->
        <div class="ordershangmeng_card title bottom" v-if="item.completePicList!=null && item.completePicList.length>0">
            <div class="ordershangmeng_card_info">完成信息</div>
            <div class="ordershangmeng_card_imgs margin_bottom_30">
                <img v-for="(imgFile,index) in item.completePicList" :key="'completedinfo'+index" :src="imgFile" />
            </div>
        </div>

    </div>
</template>

<script>
    import {Toast} from 'mint-ui';
    import keys from "../../xyg/utils/keys";

    export default{
        name: "OrderAction",
        //由于使用了动态加载组件的方式，所有props这种静态的方式，就不需要注释了
        // props:{
        //     state:String,
        //     item:Object,
        // },
        data(){
            return {
                detailRemark:'1.请携带好您的 <br> 2. <br> 3.',
                info:'1.要求本科以上学历 <br> 2.<br> 3.<br> 4.<br> 5.<br> 6.<br>',
                signFile:[],
                signViews:[],
                finishedFile:[],
                finishedView:[],
            }
        },
        mounted() {
            this.init();
        },
        methods:{
            init(){
                this.info=this.item.info;
                this.detailRemark=this.item.remark;
                console.log(this.state)
                // console.log(this.$route.params.state);
                // this.state=this.$route.params.state;
                if(this.state=='待上门') {
                    document.title = "待上门服务";
                }else if(this.state=='待完成'){
                    document.title = "待完成服务";
                }else if(this.state=='详情'){
                    document.title = "订单详情";
                }

                let that=this;
                if(this.$refs.fileUpload!=null && this.$refs.fileUpload!=undefined) {
                    this.$refs.fileUpload.addEventListener('change', function (event) {
                        // console.log(event.target);
                        // console.log(event.target.files);
                        that.actionSign(event.target);
                    });
                }

                if(this.$refs.uploadFinished!=null && this.$refs.uploadFinished!=undefined){
                    this.$refs.uploadFinished.addEventListener('change',function (event) {
                        that.actionFinished(event.target);
                    })
                }
            },
            chooseImage(){
                this.$refs.fileUpload.click();
            },
            chooseFinishedImage(){
                this.$refs.uploadFinished.click();
            },
            //签到确认
            confirmSign(){
                if (this.signFile.length>3){
                    Toast({
                        message: '最多只能选择3张图片',
                        position: 'bottom',
                        duration: 1500
                    });
                    return;
                }
                let openId=localStorage.getItem(keys.openId);
                var formData=new FormData();

                for(var i=0 ;i<this.signFile.length;i++){
                    formData.append('files[]',this.signFile[i]);
                }
                formData.append('orderNo',this.order);
                formData.append('openId',openId);

                this.$upload("/api/order/sign",formData)
                    .then(rsp=>{
                        if (rsp.code==200 && rsp.success){
                            Toast({
                                message: '提交成功',
                                position: 'bottom',
                                duration: 1500
                            });
                            this.reload(this.callback_loadWaiteGoItems);
                        }
                        else{
                            Toast({
                                message: rsp.message,
                                position: 'bottom',
                                duration: 1500
                            });
                        }
                    })
                    .catch(e=>{
                        console.log(e);
                    })
            },
            //签到
            actionSign(target){
                let files=target.files;
                if(files==null) return;
                if(files==undefined) return;
                if(files.length==0) return;
                if(files.length>3){
                    Toast({
                        message: '最多只能选择3张图片',
                        position: 'bottom',
                        duration: 1500
                    });
                    return;
                }
                if(this.signFile.length>3){
                    Toast({
                        message: '最多只能选择3张图片,不能再添加图片了',
                        position: 'bottom',
                        duration: 1500
                    });
                    return;
                }

                let that=this;
                for(var i=0 ;i<files.length;i++){
                    this.signFile.push(files[i]);

                    var reader=new FileReader();
                    reader.readAsDataURL(files[i]);
                    reader.onload=function (e) {
                        that.signViews.push(e.target.result);
                    }
                }
            },
            //完成
            confirmFinished(){
                let openId=localStorage.getItem(keys.openId);
                var formData=new FormData();

                for(var i=0 ;i<this.finishedFile.length;i++){
                    formData.append('files[]',this.finishedFile[i]);
                }
                formData.append('orderNo',this.order);
                formData.append('openId',openId);

                this.$upload("/api/order/complete",formData)
                    .then(rsp=>{
                        console.log(rsp)
                        if (rsp.code==200 && rsp.success){
                            Toast({
                                message: '提交成功',
                                position: 'bottom',
                                duration: 1500
                            });
                            this.reload(this.callback_loadWaiteFinished);
                        }
                    })
                    .catch(e=>{
                        console.log(e);
                    })
            },
            //完成
            actionFinished(target){
                let files=target.files;
                if(files==null) return;
                if(files==undefined) return;
                if(files.length==0) return;
                if(files.length>3){
                    Toast({
                        message: '最多只能选择3张图片',
                        position: 'bottom',
                        duration: 1500
                    });
                    return;
                }

                if(this.finishedFile.length>3){
                    Toast({
                        message: '最多只能选择3张图片,不能再添加图片了',
                        position: 'bottom',
                        duration: 1500
                    });
                    return;
                }

                let that=this;
                for(var i=0 ;i< files.length;i++){
                    this.finishedFile.push(files[i]);

                    var reader= new FileReader();
                    reader.readAsDataURL(files[i]);
                    reader.onload=function (e) {
                        that.finishedView.push(e.target.result);
                    }
                }
                // console.log(this.finishedFile)
            },
            showImg(path,pics){
                if(pics.length>0) {
                    this.$wx.previewImage({
                        current: path,
                        urls: pics
                    });
                }
            },
            removeSignView(idx){
                this.signFile.splice(idx,1);
                this.signViews.splice(idx,1);
            },
            removeFinishView(idx){
                this.finishedFile.splice(idx,1);
                this.finishedView.splice(idx,1);
            }
        }
    }
</script>

<style scoped>
    .ordershangmeng{
        width: 100vw;
    }
    .ordershangmeng .ordershangmeng_card{
        background-color: white;
        border-radius: 10px;
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 30px;
        box-shadow: 0px 2px 20px rgba(100,100,100,0.5);
    }
    .ordershangmeng .ordershangmeng_card .ordershangmeng_card_header{
        margin-left: 30px;
        letter-spacing: 2px;
        display: flex;
        flex-direction: column;
    }
    .ordershangmeng .ordershangmeng_card .ordershangmeng_card_header .p1{
        font-size: 26px;
        font-weight: bold;
        margin-top: 16px;
    }

    .ordershangmeng .ordershangmeng_card .ordershangmeng_card_header .p2{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 16px;
    }

    .ordershangmeng .ordershangmeng_card .ordershangmeng_card_header img{
        width: 24px;
        height: 24px;
    }
    .ordershangmeng .ordershangmeng_card .ordershangmeng_card_header .p2 .location{
        font-size: 22px;
        color: #A79F9F;
        margin-left: 10px;
    }

    .ordershangmeng .ordershangmeng_card .ordershangmeng_card_content{
        border-top: 2px #E4E4E4 dotted;
        font-size: 26px;
        display: flex;
        flex-direction: column;
    }

    .ordershangmeng .ordershangmeng_card .ordershangmeng_card_content .item{
        margin-left: 30px;
        margin-right: 30px;
        margin-top: 16px;
        letter-spacing: 2px;
        display: flex;
        flex-direction: row;
        line-height: 1;
    }

    .ordershangmeng .ordershangmeng_card .ordershangmeng_card_content .item .p1{
        color: #A79F9F;
    }
    .ordershangmeng .ordershangmeng_card .ordershangmeng_card_content .item .p2{
        flex: 1;
        margin-left: 16px;
        letter-spacing: unset !important;
    }
    .ordershangmeng .ordershangmeng_card .ordershangmeng_card_content .item .p3{

    }

    .item .p4{
        margin-top: 16px;
    }

    .ordershangmeng .ordershangmeng_card .ordershangmeng_card_content .item img{
        width: 150px;
        height: 150px;
        margin-right: 20px;
    }

    .remark{
        flex-direction: column !important;
        margin-bottom: 16px;
    }

    .title{
        display: flex;
        flex-direction: column !important;
    }

    .ordershangmeng .ordershangmeng_card .ordershangmeng_card_info{
        font-size: 28px;
        margin-left: 30px;
        margin-top: 20px;
    }
    .ordershangmeng .ordershangmeng_card .ordershangmeng_card_imgs{
        margin-left: 30px;
        margin-top: 30px;
        display: flex;
        flex-direction: row;
    }

    .margin_bottom_30{
        margin-bottom: 30px;
    }

    .ordershangmeng_card_imgs .close{
        position: absolute;
        width: 40px;
        height: 40px;
        right: 0;
        top: 0;
        text-align: center;
        line-height: 40px;
        margin-top: unset !important;
    }

    .ordershangmeng_card_imgs .close img{
        width: 40px !important;
        height: 40px !important;
    }

    .ordershangmeng .ordershangmeng_card .ordershangmeng_card_imgs img{
        width: 150px;
        height: 150px;
    }

    .ordershangmeng .ordershangmeng_card .ordershangmeng_card_tools{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
    .ordershangmeng .ordershangmeng_card .ordershangmeng_card_tools .button{
        margin-right: 30px;
        margin-top: 30px;
        color: white;
        background-color: #226DF8;
        box-shadow: 0px 2px 4px rgba(117,164,245,0.5);
        border-radius: 8px;
        padding-left: 68px;
        padding-right: 68px;
        padding-top: 23px;
        padding-bottom: 23px;
        margin-bottom: 30px;
    }
    .bottom{
        margin-bottom: 30px;
    }

    .bottom .detail_result{
        color: #226DF8;
        font-size: 26px;
        margin-left: 30px;
        margin-top: 30px;
        letter-spacing: 2px;
    }

    .bottom .untag{
        color: #FF0000 !important;
    }


    .bottom .detail_remark{
        margin-left: 30px;
        margin-top: 30px;
        margin-bottom: 20px;
        font-size: 26px;
    }
</style>

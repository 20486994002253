<template>
    <div class="jsmyinfo">
        <div class="jsmyinfo_content">
<!--            头部信息-->
            <div class="jsmyinfo_header">
                <div class="jsmyinfo_header_icon"><img :src="avatar"/></div>
                <div class="jsmyinfo_header_info">
                    <div class="nickname">{{this.nickName}}</div>
                    <div class="p">
                        <img src="assets/company_tips.png"/>
                        <div class="p1">技师</div>
                    </div>
                </div>
            </div>
        <!--填写信息1-->
            <div class="jsmyinfo_input">
                <div class="p">基本信息</div>
                <div class="item">
                    <div>真实姓名<span style="color: red">*</span></div>
                    <div class="input">
                        <input type="text" placeholder="填写真实姓名" v-model="name"/>
                    </div>
                </div>

                <div class="item">
                    <div>身份证号<span style="color: red">*</span></div>
                    <div class="input">
                        <input type="text" placeholder="填写身份证号" v-model="idCard" />
                    </div>
                </div>

                <div class="item">
                    <div>个人学历</div>
                    <div class="input">
                        <input type="text" placeholder="填写个人学历（选填）" v-model="education" />
                    </div>
                </div>

                <div class="item">
                    <div>毕业院校</div>
                    <div class="input">
                        <input type="text" placeholder="填写毕业院校（选填）" v-model="school" />
                    </div>
                </div>

                <div class="item">
                    <div>所学专业</div>
                    <div class="input">
                        <input type="text" placeholder="填写所学专业（选填）" v-model="major"/>
                    </div>
                </div>

                <div class="item">
                    <div>当前职业</div>
                    <div class="input">
                        <input type="text" placeholder="填写当前职业（选填）" v-model="profession" />
                    </div>
                </div>

                <div class="item">
                    <div>资格证书</div>
                    <div class="input">
                        <input type="text" placeholder="填写资格证书（选填）" v-model="certificate"/>
                    </div>
                </div>

                <div class="item">
                    <div>身体状态<span style="color: red">*</span></div>
                    <div class="input">

                        <input type="text" placeholder="填写身体状态（必填）" readonly v-model="healthV" @click="popupVisible=true" />
                    </div>
                </div>

                <div class="item autoItem">
                    <div class="lb inputMarginTop10">
                        <div>擅长领域</div>
                        <span  style="color: red">*</span>
                    </div>
                    <div class="input inputs">
                        <input type="text" class="inputMarginTop10" placeholder="填写擅长领域（必填）" v-model="description" />
<!--                        shanChangList-->
                        <input type="text" v-for="(inputItem,index) in shanChangList" :key="'sc'+index"  class="inputMarginTop10" placeholder="填写擅长领域（选填）" v-model="inputItem.val" />
<!--                        <input type="text"  class="inputMarginTop10" placeholder="填写擅长领域（选填）" v-model="description" />-->
                        <div class="tips">例如：西门子 PLC S7-1500，STL语言，5年</div>
                        <div style="color:#226DF8" @click="addSC()">添加</div>
                    </div>
                </div>

                <div class="item autoItem">
                    <div class="lb inputMarginTop10">
                        <div>项目经验</div>
<!--                        <span  style="color: red">*</span>-->
                    </div>
                    <div class="input inputs">
                        <div style="display: flex;flex-direction: column;" v-for="(itemProject,idx) in projects" :key="'pj'+idx">
                            <input type="text" class="inputMarginTop10" placeholder="请填写项目名称" v-model="itemProject.projectName"/>
                            <textarea class="inputMarginTop10 projectInfo" placeholder="请描述主要任务" v-model="itemProject.projectInfo" />
                        </div>
                        <div class="projectAdd" @click="addProject()">添加</div>
                    </div>
                </div>

            </div>



            <!--填写信息2-->
            <div class="jsmyinfo_input bottom_radius">
                <div class="p">收款方式</div>
                <div class="item">
                    <div>银行名称<span style="color: red">*</span></div>
                    <div class="input">
                        <input type="text" placeholder="填写银行名称" v-model="bankName" />
                    </div>
                </div>

                <div class="item">
                    <div>账户名称<span style="color: red">*</span></div>
                    <div class="input">
                        <input type="text" placeholder="填写账户名称" v-model="accountName" />
                    </div>
                </div>

                <div class="item">
                    <div>银行账号<span style="color: red">*</span></div>
                    <div class="input">
                        <input type="text" placeholder="填写银行账号" v-model="bankCard" />
                    </div>
                </div>

                <div class="item" style="display: none;">
                    <div>支行名称<span style="color: red">*</span></div>
                    <div class="input">
                        <input type="text" placeholder="填写支行名称" v-model="branchName"/>
                    </div>
                </div>


            </div>
        </div>

        <!--按钮-->
        <div class="jsmyinfo_tools">
<!--            <div class="btnback" v-on:click="goBack">返回</div>-->
            <div class="btnconfirm" @click="save">确认</div>
        </div>

<!--        身体健康-->
        <Popup class="mint-popup dtype_mint-popup"
               v-model="popupVisible"
               position="bottom">
            <Picker
                    :slots="categoryType"
                    :visible-item-count="3"
                    :show-toolbar="true"
                    ref="catapicker"
                    value-key="v"
                    class="ownpicker">
                <span @click="handeConfirmCatagoryType" class="btn-sure">确定</span>
            </Picker>
        </Popup>
    </div>
</template>

<script>
    import keys from "../../xyg/utils/keys";
    import {Toast,Popup,Picker} from 'mint-ui';
    export default {
        name: "JsMyInfo",
        components:{
            Popup,Picker
        },
        data(){
            return{
                popupVisible:false,
                categoryType:[
                    {
                        flex: 1,
                        values: [{v:"正常",key:1},{v:"良好",key:2},{v:"优秀",key:3}],
                        className: 'slot1',
                        textAlign: 'center'
                    }
                ],
                nickName:'',
                avatar:'',
                name:'',
                idCard:'',
                education:'',
                school:'',
                major:'',
                profession:'',
                certificate:'',
                healthV:'',
                healthId:0,
                description:'',
                bankName:'',
                branchName:'',
                accountName:'',
                bankCard:'',
                shanChangList:[],
                projects:[],
            }
        },
        mounted() {
            this.init();
        },
        methods:{
            init(){
                let url="/api/auth/user/technician/detail";
                let openid=localStorage.getItem(keys.openId);
                let data={openId:openid};
                this.$post(url,data)
                    .then(rsp=>{
                        if(rsp.code==200 && rsp.success){
                            let data=rsp.data;
                            this.nickName=data.nickName;
                            this.avatar=data.avatar;
                            this.name=data.name;
                            this.idCard=data.idCard;
                            this.education=data.education;
                            this.school=data.school;
                            this.major=data.major;
                            this.profession=data.profession;
                            this.certificate=data.certificate;
                            this.healthV=this.getHealthV(data.health);
                            this.healthId=data.health;
                            this.bankName=data.bankName;
                            // this.branchName=data.branchName;
                            this.accountName=data.accountName;
                            this.bankCard=data.bankCard;
                            let _desc=data.description;
                            let _descArray=_desc.split('@$@');
                            console.log('desc array',_descArray);
                            if(_descArray!=null && _descArray.length>0){
                                let f=_descArray[0];
                                this.description=f;
                                for(var i=1;i<_descArray.length;i++){
                                    if(_descArray[i]!=''){
                                        this.shanChangList.push({idx:(i-1),val:_descArray[i]});
                                    }
                                }
                            }

                            let experiences=data.experiences;
                            if(experiences!=null && experiences.length>0){
                                experiences.forEach(t=>{
                                    let len=this.projects.length;
                                    if(t.name != '' || t.detail!=''){
                                        this.projects.push({idx:(len+1),projectName:t.name,projectInfo:t.detail});
                                    }
                                });
                            }

                        }
                    })
                    .catch(e=>{
                        console.log(e);
                    })
            },
            save(){
                if(this.name==''){
                    Toast({
                        message: '请输入真实姓名',
                        position: 'bottom',
                        duration: 1500
                    });
                    return;
                }

                if(this.idCard==''){
                    Toast({
                        message: '请输入身份证号',
                        position: 'bottom',
                        duration: 1500
                    });
                    return;
                }

                let validIdCardOk=this.validIdCard();
                if(!validIdCardOk[0]){ //验证身份证
                    Toast({
                        message: validIdCardOk[1],
                        position: 'bottom',
                        duration: 1500
                    });
                    return;
                }

                let validBankCardOk=this.validBankNum();
                if(!validBankCardOk[0]){
                    Toast({
                        message: validBankCardOk[1],
                        position: 'bottom',
                        duration: 1500
                    });
                    return;
                }

                if(this.description==''){
                    Toast({
                        message: '请输入擅长领域',
                        position: 'bottom',
                        duration: 1500
                    });
                    return;
                }

                let newDescription=this.description;
                this.shanChangList.forEach(t=>{
                    if(t!='') {
                        newDescription = newDescription + '@$@' + t.val;
                    }
                });
                let _projects=null;
                if(this.projects.length>0){
                    _projects=[];
                    this.projects.forEach(t=>{
                        _projects.push({name:t.projectName,detail:t.projectInfo});
                    });
                }

                let openid=localStorage.getItem(keys.openId);
                let reqUrl="/api/auth/user/technician/update";
                let data={
                    accountName:this.accountName,
                    avatar:this.avatar,
                    bankCard:this.bankCard,
                    bankName:this.bankName,
                    branchName:this.branchName,
                    certificate:this.certificate,
                    description:newDescription,
                    education:this.education,
                    health:this.healthId,
                    idCard:this.idCard,
                    major:this.major,
                    name:this.name,
                    nickName:this.nickName,
                    openId:openid,
                    profession:this.profession,
                    school:this.school,
                    experiences:_projects,
                }

                this.$post(reqUrl,data)
                    .then(rsp=>{
                        if(rsp.code==200 && rsp.success){
                            Toast({
                                message: '提交成功',
                                position: 'bottom',
                                duration: 1500
                            });

                            this.closeDraw();

                        }
                        else{
                            Toast({
                                message: '保存失败',
                                position: 'bottom',
                                duration: 1500
                            });
                        }
                    })
                    .catch(e=>{
                        console.log(e);
                    })
            },
            addSC(){
                let len=this.shanChangList.length;
                if(len>=4) {
                    Toast({
                        message: '最多只能添加5个擅长领域',
                        position: 'bottom',
                        duration: 1500
                    });
                    return;
                }
                this.shanChangList.push({idx:(len+1),val:''});
            },
            addProject(){
                let len=this.projects.length;
                this.projects.push({idx:(len+1),projectName:'',projectInfo:''});
            },
            handeConfirmCatagoryType(){
                let selected=this.$refs.catapicker.getValues()[0];
                let selected_v=selected.v;
                let selected_id=selected.key;
                console.log(selected);
                this.healthV=selected_v;
                this.healthId=selected_id;
                this.popupVisible=false;
            },
            getHealthV(id){
                if(id==1) return '正常';
                if(id==2) return '良好';
                if(id==3) return '优秀';
                return '请选择身体情况'
            },
            validIdCard(){ //验证身份证Id是否合法
                var msg, boo; //  msg身份证验证友情提示,boo返回值
                if (!this.idCard) {
                    return [boo = false, msg = "身份证号码不能为空"];
                }
                var num = this.idCard.toUpperCase(); // 身份证为末尾可能是X
                //   身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，
                //   最后一位是校验位，可能为数字或字符X。
                const arrInt = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2);
                const arrCh = new Array('1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2');
                var nTemp = 0,
                    i;
                if (!(/(^\d{15}$)|(^\d{17}([0-9]|X)$)/.test(num))) {
                    //  输入的身份证号长度不对，或者号码不符合规定;
                    return [boo = false, msg = "输入的身份证号长度不对，或者号码不符合规定"];
                }
                //   校验位按照ISO 7064:1983.MOD 11-2的规定生成，X可以认为是数字10。
                //   下面分别分析出生日期和校验位
                var len, re;
                len = num.length;
                if (len == 15) {
                    re = new RegExp(/^(\d{6})(\d{2})(\d{2})(\d{2})(\d{3})$/);
                    let arrSplit = num.match(re);
                    //   检查生日日期是否正确
                    let dtmBirth = new Date('19' + arrSplit[2] + '/' + arrSplit[3] + '/' + arrSplit[4]);
                    let bGoodDay;
                    bGoodDay = (dtmBirth.getYear() == Number(arrSplit[2])) && ((dtmBirth.getMonth() + 1) == Number(arrSplit[3])) && (dtmBirth.getDate() == Number(arrSplit[4]));
                    if (!bGoodDay) {
                        //   输入的身份证号里出生日期不对！
                        return [boo = false, msg = "输入的身份证号里出生日期不对！"];

                    } else {
                        //   将15位身份证转成18位
                        //   校验位按照ISO 7064:1983.MOD 11-2的规定生成，X可以认为是数字10。
                        num = num.substr(0, 6) + '19' + num.substr(6, num.length - 6);
                        for (i = 0; i < 17; i++) {
                            nTemp += num.substr(i, 1) * arrInt[i];
                        }
                        num += arrCh[nTemp % 11];
                        return [boo = true, msg = num];
                    }
                }
                if (len == 18) {
                    re = new RegExp(/^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$/);
                    let arrSplit = num.match(re);
                    //  检查生日日期是否正确
                    let dtmBirth = new Date(arrSplit[2] + "/" + arrSplit[3] + "/" + arrSplit[4]);
                    let bGoodDay;
                    bGoodDay = (dtmBirth.getFullYear() == Number(arrSplit[2])) && ((dtmBirth.getMonth() + 1) == Number(arrSplit[3])) && (dtmBirth.getDate() == Number(arrSplit[4]));
                    if (!bGoodDay) {
                        //  输入的身份证号里出生日期不对！

                        return [boo = false, msg = " 输入的身份证号里出生日期不对"];
                    } else {
                        //  检验18位身份证的校验码是否正确。
                        //  校验位按照ISO 7064:1983.MOD 11-2的规定生成，X可以认为是数字10。
                        var valnum;
                        for (i = 0; i < 17; i++) {
                            nTemp += num.substr(i, 1) * arrInt[i];
                        }
                        valnum = arrCh[nTemp % 11];
                        if (valnum != num.substr(17, 1)) {
                            //  18位身份证的校验码不正确！
                            return [boo = false, msg = "身份证的校验码不正确！"];
                        }
                        return [boo = true, msg = "验证成功"]
                    }
                }
                return [boo = false, msg = "身份证的长度不正确！"];
            },
            validBankNum() {
                var boo,msg;
                if(this.bankCard.length<12 || this.bankCard.length>19){
                    return [boo=false,msg="银行卡号长度必须在12到19之间"];
                }
                if(!(/^[0-9]*$/.test(this.bankCard))){
                    return [boo=false,msg="银行卡号错误"];
                }
                return [boo=true,msg="银行卡号正确"];
            }
        }
    }
</script>

<style scoped>
    .jsmyinfo{
        width: 750px;
    }
    .jsmyinfo .jsmyinfo_content{
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 20px;
        background-color: white;
        border-radius: 10px;
        /*border-top-left-radius: 10px;*/
        /*border-top-right-radius: 10px;*/
        /*border-bottom-left-radius: 10px;*/
        /*border-bottom-right-radius: 10px;*/
    }

    .jsmyinfo .jsmyinfo_content .jsmyinfo_header{
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .jsmyinfo .jsmyinfo_content .jsmyinfo_header .jsmyinfo_header_icon{
        width: 100px;
        height: 100px;
        margin-left: 40px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .jsmyinfo .jsmyinfo_content .jsmyinfo_header .jsmyinfo_header_icon img{
        width: 100px;
        height: 100px;
        border-radius: 50px;
    }

    .jsmyinfo .jsmyinfo_content .jsmyinfo_header .jsmyinfo_header_info{
        display: flex;
        flex-direction: column;
        margin-left: 24px;
        letter-spacing: 2px;

    }

    .jsmyinfo .jsmyinfo_content .jsmyinfo_header .jsmyinfo_header_info .nickname{
        font-size: 26px;
    }

    .jsmyinfo .jsmyinfo_content .jsmyinfo_header .jsmyinfo_header_info .p{
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 22px;
        color: #A79F9F;
        margin-left: 4px;
        margin-top: 16px;
    }
    .jsmyinfo .jsmyinfo_content .jsmyinfo_header .jsmyinfo_header_info .p1{
        margin-left: 8px;
    }

    .jsmyinfo .jsmyinfo_content .jsmyinfo_header .jsmyinfo_header_info img{
        width: 14px;
        height: 18px;
    }

    .jsmyinfo .jsmyinfo_input{
        margin-top: 2px;
        background-color: white;
        border-top: 2px #E7EAF1 solid;
        letter-spacing: 2px;
    }

    .jsmyinfo .bottom_radius{
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .jsmyinfo .jsmyinfo_input .p{
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: 40px;
        font-weight: bold;
        font-size: 28px;
    }

    .jsmyinfo .jsmyinfo_input .item{
        margin-left: 40px;
        margin-right: 40px;
        height: 120px;
        font-size: 26px;

        display: flex;
        flex-direction: row;
        align-items: center;


        margin-bottom: 4px;
        border-bottom: 4px #FCFCFC solid;
    }

    .jsmyinfo .jsmyinfo_input .item .lb{
        display: flex;
        flex-direction: row;
    }

    .jsmyinfo .jsmyinfo_input .item .input{
        margin-left: 50px;
        flex:1;
    }

    .jsmyinfo .jsmyinfo_input .item .input input[type='text']{
        border-width:0px;
        height: 40px;
        border: none;
        outline: medium;
    }

    .jsmyinfo .jsmyinfo_tools{
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .jsmyinfo .jsmyinfo_tools .btnback{
        background-color: #D1CECE;
        border-radius: 10px;
        color: #7A7777;
        font-size: 28px;
        letter-spacing: 4px;
        width: 200px;
        height: 80px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .jsmyinfo .jsmyinfo_tools .btnconfirm{
        background-color: #226DF8;
        border-radius: 10px;
        color: #FFFFFF;
        font-size: 28px;
        letter-spacing: 4px;
        width: 200px;
        height: 80px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .inputMarginTop10{
        margin-top: 10px;
    }
    .inputs{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    .tips{
        font-size: 22px;
        color: #D3D3D3;
        letter-spacing: unset !important;
        margin-bottom: 20px;
        margin-top: 20px;
    }

    .autoItem{
        height: unset !important;
        align-items: flex-start !important;
    }

    .projectInfo{
        height: 150px !important;
    }
    .projectAdd{
        margin-top: 10px;
        color:#226DF8;
        margin-left: 10px;
    }

    .dtype_mint-popup{
        width: 750px;
    }

    .btn-sure{

    }
    .ownpicker >>> .picker-toolbar{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        margin-right: 20px;
        color:#226DF8;
    }
</style>

<template>
    <div id="root">

<!--        <wx-open-launch-weapp-->
<!--                id="launch-btn"-->
<!--                ref="btnMini"-->
<!--                username="gh_054673fe64e5"-->
<!--                :path="'/pages/index/index.html?opid='+openId"-->
<!--                env-version="trial"-->
<!--                @launch="weLaunch"-->
<!--                @error="errorWeApp"-->
<!--                style="width: 100px;height: 100px;"-->
<!--        >-->
<!--            <script type="text/wxtag-template">-->
<!--                <style>.btn { padding: 12px }</style>-->
<!--                <button class="btn">去授权</button>-->
<!--            </script>-->
<!--        </wx-open-launch-weapp>-->




        <Tabbar tab-height="tabbar_height_100">

            <template slot="container">
                <TabContainer v-model="active" class="TabContainer_clear" swipeable>
                    <TabContainerItem  id="index_home">
                        <Home2 :rule="rule" @showOrderAction="openDraw" @closeOrderAction="closeDraw" />
                    </TabContainerItem>
                    <TabContainerItem  id="index_order">
                        <OrderList :action="active" v-if="rule=='技师'" @showOrderAction="openDraw" @closeOrderAction="closeDraw" ref="orderList" />
                        <ReqList :action="active" v-else-if="rule=='公司'" @showGsOrderAction="openDraw" @closeOrderAction="closeDraw" ref="reqList" />
                        <div v-else class="order_unlogin">
                            <img src="assets/default_unlogin.png"/>
                            <div class="txt">
                                <div style="color:#226DF8" @click="tologin">登录</div>
                                <div>后获取更多精彩内容</div>
                            </div>
                        </div>
                    </TabContainerItem>
                    <TabContainerItem  id="index_my">
                        <My :action="active" :ruleName="rule" @showMyInfo="openDraw" @closeOrderAction="closeDraw" @changeTab="changeOrderPageIndex" ref="myPanel"/>
                    </TabContainerItem>
                </TabContainer>
            </template>

            <template slot="items">
                <TabbarItem v-on:currentPageIndex="changeOrder"
                            name="index_home"
                            showTxt="首页"
                            :idx="tabItemIdx.index_home"
                            :icons="['assets/index_home_unselected.png','assets/index_home_selected.png']">
                    <template slot="icon" slot-scope="scope">
                        <img :src="scope.url" class="iconImg"/>
                    </template>
                </TabbarItem>
                <TabbarItem v-on:currentPageIndex="changeOrder"
                            name="index_order"
                            :showTxt="secondName"
                            :idx="tabItemIdx.index_order"
                            :icons='["assets/index_order_unselected.png","assets/index_order_selected.png"]'>
                    <template slot="icon" slot-scope="scope">
                        <img :src="scope.url" class="iconImg"/>
                    </template>
                </TabbarItem>
                <TabbarItem v-on:currentPageIndex="changeOrder"
                            name="index_my"
                            showTxt="我的"
                            :idx="tabItemIdx.index_my"
                            :icons='["assets/index_my_unselected.png","assets/index_my_selected.png"]'>
                    <template slot="icon" slot-scope="scope">
                        <img :src="scope.url" class="iconImg"/>
                    </template>
                </TabbarItem>
            </template>
        </Tabbar>

        <!--  抽屉-->
        <div class="drawer-container" :class="drawVisiable">
            <div class="bg" :class="drawVisiable"></div>
            <div class="drawer" :class="drawVisiable">
                <div class="close" v-on:click="closeDraw">
                    <img src="assets/close.png" />
                </div>
                <div id="drawcomponent" ref="drawcomponent">
                    <div id="drawComponentContainer"></div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import Tabbar from "../xyg/tabbar/tabbar";
    import TabbarItem from "../xyg/tabbar/tabbarItem";
    import {TabContainer,TabContainerItem} from 'mint-ui';

    import Home2 from "./Home2";
    import My from "./My";
    import ReqList from "./gongsi/ReqList";

    import OrderList from "./jishi/OrderList";
    import tools from "../xyg/utils/tools";
    import keys from "../xyg/utils/keys";

    export default {
        name: "Container",
        components: {
            Tabbar,
            TabbarItem,
            Home2,
            TabContainer,
            TabContainerItem,
            OrderList,
            My,
            ReqList,
        },
        data(){
            return {
                openId:'',
                rule:'', //默认"" 1:公司 2:技师
                active:'index_home',
                // drawComponent:()=>import('./jishi/OrderAction.vue'),
                drawComponent:null,
                drawVisiable:'drawer-closing',
                tabItemIdx:{
                    index_home:1,
                    index_order:0,
                    index_my:0
                } //初始化图标
            }
        },
        mounted(){
            this.init();
        },
        computed:{
            secondName(){
                return "订单";
                // if(this.rule=='公司'){
                //     return "需求";
                // }
                // else {
                //     return "订单";
                // }
            }
        },
        watch:{
            active:function (nV,oV) {
                this.currentPageIndex(nV);
            }
        },
        methods:{
            init(){
                document.title="篱笆匠人";
                this.openId=localStorage.getItem(keys.openId);
                //修改此处，切换角色
                let role=localStorage.getItem(keys.userType);
                if(role==1){
                    this.rule='公司';
                }
                else if(role==0){
                    this.rule='技师';
                }else{
                    this.rule="";
                }
                var getCode=tools.QueryString("code");
                console.log('code',getCode)
                if(getCode!=""){
                    //请求接口获取用户昵称和openid
                    let url="/api/common/login";
                    console.log(url);
                    this.$post(url,{code:getCode})
                        .then(rsp=>{
                            console.log(rsp);
                            console.log(rsp.code);
                            if(rsp.code==200 && rsp.success){
                                let data=rsp.data;
                                try {
                                    let openId = data.openId;
                                    let nickName = data.nickName;
                                    let headUrl=data.avatar;
                                    let userType=data.userType;
                                    localStorage.setItem(keys.openId,openId);
                                    localStorage.setItem(keys.nickName,nickName);
                                    localStorage.setItem(keys.headUrl,headUrl);
                                    localStorage.setItem(keys.userType,userType);
                                    //判断用户类型
                                    if(userType==-1){
                                        //新用户
                                        this.$router.push({path:'/selectrole'});
                                    }else {
                                        window.location.href = '/';
                                    }
                                }
                                catch (e) {
                                    console.log("error:"+e);
                                    alert("授权失败！");
                                }
                            }else{
                                window.location.href="/";
                            }
                        })
                        .catch(error=>{
                           console.log(error);
                           window.location.href="/"
                        });
                }

            },
            weLaunch(e){
                console.log('weLaunch',e);
            },
            errorWeApp(e){
                console.log('errorWeApp',e);
            },
            currentPageIndex(idx){
                if(idx=="index_home") {
                    document.title="篱笆匠人";
                    this.$set(this.tabItemIdx, "index_home", 1);
                    this.$set(this.tabItemIdx, "index_order", 0);
                    this.$set(this.tabItemIdx, "index_my", 0);
                    return;
                }
                if(idx=="index_order") {
                    document.title="订单";
                    this.$set(this.tabItemIdx, "index_home", 0);
                    this.$set(this.tabItemIdx, "index_order", 1);
                    this.$set(this.tabItemIdx, "index_my", 0);
                    return;
                }
                if(idx=="index_my") {
                    document.title="我的";
                    this.$set(this.tabItemIdx, "index_home", 0);
                    this.$set(this.tabItemIdx, "index_order", 0);
                    this.$set(this.tabItemIdx, "index_my", 1);
                    return;
                }
            },
            closeDraw(){
                this.drawVisiable='drawer-closing';
            },
            openDraw(control){
                let parent=this.$refs.drawcomponent;
                let child=parent.firstChild;
                parent.removeChild(child);
                let newComponent=document.createElement("div");
                newComponent.id='drawComponentContainer';
                parent.appendChild(newComponent);
                control.component.$mount('#drawComponentContainer');
                this.drawVisiable='drawer-opening';
            },
            changeOrder(name){
                this.active=name;
            },
            changeOrderPageIndex(pageIndex){
                this.active='index_order';
                if(this.rule=='公司'){
                    this.$refs.reqList.resetPageIndex(pageIndex);
                }
                else {
                    this.$refs.orderList.resetPageIndex(pageIndex);
                }
            },
            tologin(){
                this.active="index_my";
                this.$refs.myPanel.tologin();
            }
        }
    }
</script>

<style>
    #root{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 750px;
        max-width: 750px;
        flex: 1;
    }

    /*.mint-tab-item-container{*/
    /*  position:absolute;*/
    /*  height: 100%;*/
    /*  display: flex;*/
    /*  flex-direction: column;*/
    /*  align-items: center;*/
    /*  width: 750px;*/
    /*}*/

    .xyg-example{
        height: 30px;
        overflow: scroll;
    }

    .iconImg{
        width: 62px;
        height: 62px;
    }

    .TabContainer_clear{
        position:unset !important;
    }

    .mint-tab-container-item::-webkit-scrollbar{
        display: none;
    }

    .mint-tab-container::-webkit-scrollbar{
        display: none;
    }
    .mint-tab-container-wrap::-webkit-scrollbar{
        display: none;
    }

    .mint-tab-item-label{
        font-size: 14px !important;
    }

    /*抽屉*/
    .drawer-container{
        position: fixed;
        width: 750px;
        /*height: 100vh;*/
        /*top:0;*/
        transition: height 500ms cubic-bezier(0.7,0.3,0.1,1);
        left: 0;
        bottom: 0;
        z-index:10;
    }

    .drawer-closing{
        height: 0 !important;
    }

    .drawer-opening{
        height: 100vh !important;
    }

    .drawer-container .bg{
        width: 750px;
        /*height: 100vh;*/
        transition: height 500ms cubic-bezier(0.7,0.3,0.1,1);
        background-color:black;
        opacity: 0.5;
        z-index:10;
    }

    .drawer-container .drawer{
        /*height: 100vh;*/
        transition: height 500ms cubic-bezier(0.7,0.3,0.1,1);
        width: 750px;
        position: absolute;
        top: 0px;
        right: 0;
        background-color: white;
        transform: translate(0);
        overflow: scroll;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .drawer-container .close{
        /*position: relative;*/
        /*top: 0;*/
        /*left: 0;*/
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 750px;
        height: 40px;
        color: white;
        font-size: 28px;
        z-index:100;
        margin-top: 10px;
        padding-bottom: 10px;
        border-bottom: 2px #F6F6F6 solid;
    }
    .drawer-container .close img{
        width: 40px;
        height: 40px;
    }


    .drawer-container .drawer::-webkit-scrollbar{
        display: none;
    }

    #drawcomponent{
        overflow: scroll;
        height: 100vh;
    }

    #drawcomponent::-webkit-scrollbar{
        display: none;
    }

    .order_unlogin{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 20%;
    }

    .order_unlogin img{
        width: 450px;
        height: 356px;
    }

    .order_unlogin .txt{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 28px;
        letter-spacing: 2px;
    }

</style>

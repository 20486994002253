import Vue from 'vue'
import Router from 'vue-router'

import JsMyInfo from './page/jishi/JsMyInfo';
import Container from "./page/Container";
import JsOrderAction from './page/jishi/OrderAction'

Vue.use(Router) //这句别忘记了

export default new Router({
    mode:'history',  //使用history防止url中出现#
    routes:[
        {
            name:'index',
            path: '/index.html',
            component: Container,
        },
        {
            path: '/',
            component: Container,
        },
        {
            //技师个人信息
            name:'JsMyInfo',
            path:'/JsMyInfo',
            component:JsMyInfo
        },
        {
            //技师订单操作
            name:'JsOrderAction',
            path:'/JsOrderAction',
            component:JsOrderAction
        },
        {
            //技师 投标列表
            name:"ReqList",
            path:"/ReqList",
            component:()=>import('./page/jishi/ReqList.vue'),
            params:{
                cardType: 0
            }
        },
        {
            //技师 投标明细
            name:"ReqDetail",
            path:"/ReqDetail",
            component:()=>import("./page/jishi/ReqDetail.vue")
        },
        {
            //合作
            name:'hezuo',
            path:"/hezuo",
            component:()=>import('./page/common/HeZuoHuoBan.vue')
        },
        {
            //培训
            name:'px',
            path:'/px',
            component:()=>import('./page/common/JiNengPeixun.vue')
        },
        {
            //培训详细
            name:'pxdetail',
            path:'/pxdetail',
            component:()=>import('./page/common/JiNengPeiXunDetail.vue')
        },
        {
            //案例分享
            name:'case',
            path:'/case',
            component:()=>import('./page/common/CaseShared.vue')
        },
        {
            //案例明细
            name:'caseDetail',
            path:'/caseDetail',
            component:()=>import('./page/common/CaseSharedDetail.vue')
        },
        {
            //备品备件
            name:'bpbj',
            path:'/bpbj',
            component:()=>import('./page/common/BeiPingBeiJian.vue')
        },
        {
            //选择角色
            name:'selectrole',
            path:'/selectrole',
            component:()=>import('./page/SelectRole.vue')
        }
    ],
    // beforeEach(to, from, next) {
    //     var u = navigator.userAgent;
    //     var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    //     if(isiOS && to.path!==location.pathname){
    //         location.assign(to.fullPath)
    //     }
    // }
})

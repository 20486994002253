<template>
    <div class="my">
        <!--        头部卡片-->
        <div class="my_top">
            <div class="my_top_info">
                <div class="my_top_info_head"><img :src="headImg"/></div>
                <div class="my_top_info_comppany">
                    <div class="company_name" :class="company_name_bord" v-on:click="tologin">{{nickName}}</div>
                    <div class="info" :class="islogin?'':'unmarginleft'">
                        <div class="icon" v-if="islogin"><img src="assets/company_tips.png"/></div>
                        <div class="types" v-if="islogin && rule==userTypeStr.jishi">技师</div>
                        <div class="types" v-if="islogin && rule==userTypeStr.gongsi">公司</div>
                        <div class="types" :class="islogin?'':'unmarginleft'" v-else-if="!islogin">登录后获取更多精彩内容</div>
                    </div>
                </div>
            </div>
            <!--            技师订单面板-->
            <div class="my_top_require" v-if="rule==userTypeStr.jishi">
                <div class="title">
                    <div class="p1">我的订单</div>
                    <div class="p2">
                        <div @click="toAllOrders('all')">全部订单</div>
                        <img src="assets/go1.png"/></div>
                </div>

                <div class="sum">
                    <div class="item" @click="toAllOrders('all')">
                        <span>待选标</span>
                        <span class="count">{{waitSelect}}</span>
                    </div>
                    <div class="item" @click="toAllOrders('waite')">
                        <span>待上门</span>
                        <span class="count">{{waitService}}</span>
                    </div>
                    <div class="item"  @click="toAllOrders('all')">
                        <span>已完成</span>
                        <span class="count">{{finished}}</span>
                    </div>
                </div>

            </div>

            <!--            公司服务面板-->
            <div class="my_top_require" v-if="rule==userTypeStr.gongsi">
                <div class="title">
                    <div class="p1">我的需求</div>
                    <div class="p2">
                        <div @click="toAllOrders('all')">全部需求</div>
                        <img src="assets/go1.png"/></div>
                </div>

                <div class="sum">
                    <div class="item" @click="toAllOrders('all')">
                        <span>待审核</span>
                        <span class="count">{{waitService}}</span>
                    </div>
                    <div class="item" @click="toAllOrders('confirm')">
                        <span>进行中</span>
                        <span class="count">{{waitSelect}}</span>
                    </div>
                    <div class="item" @click="toAllOrders('all')">
                        <span>已完成</span>
                        <span class="count">{{finished}}</span>
                    </div>
                </div>

            </div>

        </div>
        <!--        菜单目录-->
        <div class="my_menu">
            <!--            技师个人信息-->
            <div class="item" v-on:click="toMyInfo" v-if="rule==userTypeStr.jishi">
                <div class="item_icon"><img src="assets/menu_icon_1.png"/></div>
                <div class="item_txt">个人信息</div>
                <div class="item_goto2"><img src="assets/goto2.png"/></div>
            </div>
            <!--            公司个人信息-->
            <div class="item" v-on:click="toGsMyInfo" v-if="rule==userTypeStr.gongsi">
                <div class="item_icon"><img src="assets/menu_icon_1.png"/></div>
                <div class="item_txt">企业信息</div>
                <div class="item_goto2"><img src="assets/goto2.png"/></div>
            </div>
            <!--            需求发布-->
            <div class="item item_spliter" v-on:click="toPostReqs" v-if="rule==userTypeStr.gongsi">
                <div class="item_icon"><img src="assets/menu_icon_2.png"/></div>
                <div class="item_txt">需求发布</div>
                <div class="item_goto2"><img src="assets/goto2.png"/></div>
            </div>
            <div class="item item_spliter" v-if="openId!='' && openId!=undefined && openId!=null">
                <div class="item_icon"><img src="assets/menu_icon_6.png"/></div>
                <div class="item_txt item_txt_wxmini">
                    <wx-open-launch-weapp
                            id="launch-btn"
                            username="gh_054673fe64e5"
                            :path="'/pages/index/index.html?opid='+openId"
                            env-version="trial"
                            style="position:absolute ; width: 100% ; height: 100%;top: 0; left: 0;display: flex;flex-direction:column; justify-content: center;"
                    >
                        <script type="text/wxtag-template">
                            <style>
                                .bindphone{
                                    color: #797979;
                                    letter-spacing: 2px;
                                }
                            </style>
                            <div class="bindphone"> 绑定手机号 </div>
                        </script>
                    </wx-open-launch-weapp>
                </div>
                <div class="item_goto2"><img src="assets/goto2.png"/></div>
            </div>
            <div class="item item_spliter" @click="toProxy">
                <div class="item_icon"><img src="assets/menu_icon_3.png"/></div>
                <div class="item_txt">用户协议</div>
                <div class="item_goto2"><img src="assets/goto2.png"/></div>
            </div>
            <div class="item item_spliter" v-on:click="showPhone">
                <div class="item_icon"><img src="assets/menu_icon_4.png"/></div>
                <div class="item_txt">客服电话</div>
                <div class="item_goto2"><img src="assets/goto2.png"/></div>
            </div>
            <div class="item item_spliter">
                <div class="item_icon"><img src="assets/menu_icon_5.png"/></div>
                <div class="item_txt">关于篱笆</div>
                <div class="item_goto2"><img src="assets/goto2.png"/></div>
            </div>
        </div>
        <!--        退出系统-->
        <div class="my_button" v-if="islogin==true" @click="loginOut">
            <div>退出系统</div>
        </div>

        <!--        上拉数据-->
        <Actionsheet v-model="actionVisible"
                     :actions="actionData"
        ></Actionsheet>
        <Popup class="popup_tips" v-model="popupVisible" position="bottom" v-bind:closeOnClickModal="false" >
            <div class="read">
                <div v-html="info" class="read_content"></div>
                <div class="read_tools">
                    <span v-if="showTime" style="color:red">{{time}}  </span><div @click="toClose" :class="clickCls">我已经阅读</div>
                </div>
            </div>
        </Popup>
    </div>
</template>

<script>
    import Vue from 'vue';
    import {Actionsheet,Popup} from 'mint-ui';
    import keys from "../xyg/utils/keys";
    import tools from "../xyg/utils/tools";

    export default {
        name: "My",
        props: {
            ruleName: String,
            action:String,
        },
        data(){
            return{
                openId:'',
                popupVisible:false,
                info:'数据加载中,请稍后...',
                time:60,
                clickCls:'clickCls',
                showTime:true,

                headImg:'',//头像
                nickName:'',//昵称
                company_name_bord:'',//昵称样式
                islogin:false,
                actionVisible:false,
                waitSelect:'',
                waitService:'',
                finished:'',
                actionData:[
                    {
                        name:'客服电话：13382187048',
                        method:this.callPhone,
                    }
                ],
                userTypeStr:keys.userTypeStr,
                rule:'',
            }
        },
        mounted(){
            this.init();
        },
        components:{
            Actionsheet,
            Popup
        },
        watch:{
            ruleName:function (n,o) {
                this.rule=n;
            },
            action:function (n,o) {
                if(n=='index_my'){
                    this.init();
                }
            }
        },
        methods:{
            toMyInfo(){
                let that=this;
                let path=`./jishi/JsMyInfo.vue`
                this.registerComponent(path).then(component=>{
                    let control={name:'showMyInfo',component: new component({
                            methods:{
                                closeDraw(){
                                    that.loadOrder();
                                    that.$emit('closeOrderAction',null);
                                }
                            }
                        })};
                    this.$emit('showMyInfo',control);
                });

            },
            toGsMyInfo(){
                let path=`./gongsi/GsMyInfo.vue`;
                let that=this;
                this.registerComponent(path).then(component=>{
                    let control={name:'showMyInfo',component: new component({
                            methods:{
                                closeDraw(){
                                    that.loadOrder();
                                    that.$emit('closeOrderAction',null);
                                }
                            }
                        })};
                    this.$emit('showMyInfo',control);
                });
            },
            callPhone(){
                let phone='13382187048'
                //拨打电话怒
                window.location.href='tel://'+phone;
            },
            showPhone(){
                this.actionVisible=true;
            },
            registerComponent(path) { //注册操作
                return import(`${path}`).then(component => {
                    return Vue.extend(component.default);
                });
            },
            toAllOrders(pageIndex){
                //切换到订单/需求选中
                this.$emit('changeTab', pageIndex);
            },
            init(){
                let open_id=localStorage.getItem(keys.openId);
                let userType=localStorage.getItem(keys.userType);
                this.openId=open_id;

                console.log("openId========",this.openId);
                if(tools.StringIsBank(open_id)){ //未登录情况
                    //未登录，需要登录
                    //设置默认头像
                    this.headImg='assets/default_head.png';
                    //设置昵称
                    this.nickName='点击登录';
                    //设置昵称粗细
                    this.company_name_bord="company_name_bord";
                    //是否登录
                    this.islogin=false;
                    //订单统计数据
                    this.waitSelect='--';
                    this.waitService='--';
                    this.finished='--';
                }else{
                    this.islogin=true;
                    this.headImg=localStorage.getItem(keys.headUrl);
                    this.nickName=localStorage.getItem(keys.nickName);
                    this.waitSelect="0";
                    this.waitService="0";
                    this.finished="0";
                    if(userType==-1) {
                        this.$router.push({path:'/selectrole'});
                    }
                    //加载订单或者需求
                    this.loadOrder();
                }
            },
            tologin(){
                if(this.islogin) return;
                //显示协议
                // let isReader=localStorage.getItem(keys.isReader);
                // if(isReader!=null && isReader!=undefined && isReader==1) return;
                let url='/api/common/index';
                this.$post(url,null)
                    .then(rsp=>{
                        this.info=rsp;
                        setInterval(()=>{
                            if(this.time>0){
                                this.time=this.time-1;
                            }
                            else{
                                this.showTime=false;
                                this.clickCls='clickClsOK';
                            }
                        },1000);
                    })
                    .catch(e=>{
                        console.log(e);
                    })
                this.popupVisible=true;
            },
            loadOrder(){
                let userType=localStorage.getItem(keys.userType);
                let openId=localStorage.getItem(keys.openId);
                if(userType==1) {//企业
                    let gsUrl='/api/product/count';
                    this.$post(gsUrl,{openId:openId})
                        .then(rsp=>{
                            try{
                                if(rsp.code==200 && rsp.success){
                                    this.waitSelect=rsp.data.working;
                                    this.waitService=rsp.data.waiting;
                                    this.finished=rsp.data.complete;
                                }
                            }catch (e) {
                                console.log(e);
                            }
                        })
                        .catch(e=>{
                            console.log(e);
                        })
                    return;
                }

                if(userType==0){ //技工
                    let jsUrl="/api/order/count";
                    this.$post(jsUrl,{openId:openId})
                        .then(rsp=>{
                            try{
                                if(rsp.code==200 && rsp.success){
                                    this.waitSelect=rsp.data.selecting;
                                    this.waitService=rsp.data.signing;
                                    this.finished=rsp.data.finished;
                                }
                            }catch (e) {
                                console.log(e);
                            }
                        })
                        .catch(e=>{
                            console.log(e);
                        })
                    return;
                }
            },
            toPostReqs(){
                let path=`./gongsi/PostReqs.vue`;
                let that=this;
                this.registerComponent(path).then(component=>{
                    let control={name:'JsMyInfo',component: new component({
                            methods:{
                                closeDraw(){
                                    that.loadOrder();
                                    that.$emit('closeOrderAction',null);
                                }
                            }
                        })};
                    this.$emit('showMyInfo',control);
                });
            },
            loginOut(){
                localStorage.removeItem(keys.headUrl);
                localStorage.removeItem(keys.nickName);
                localStorage.removeItem(keys.openId);
                localStorage.removeItem(keys.userType);
                this.rule="";
                this.init();
            },
            toProxy(){
                window.location.href=keys.webSite+'/doc/index.html';
            },
            toClose(){
                if(this.time==0){
                    this.popupVisible=false;
                    localStorage.setItem(keys.isReader,1);

                    let redirect= encodeURIComponent(keys.webSite);
                    let wxcodeUrl=keys.getWxCodeUrl.replace('{0}',redirect);
                    console.log(wxcodeUrl);
                    window.location.href=wxcodeUrl;
                }
            }
        }
    }
</script>

<style scoped>
    .my{
        display: flex;
        flex-direction: column;
        padding-bottom: 160px;
        /*height: 100vh;*/
        flex:1;
    }

    .my::-webkit-scrollbar{
        display: none;
    }

    .my_top{
        border-radius: 10px;
        background-color: white;
        box-shadow: 0px 2px 20px rgba(100,100,100,0.5);
        width: 710px;
        margin-top:20px;
        margin-left:20px;
        margin-right: 20px;

    }

    /*我的信息*/
    .my_top_info{
        margin-left: 40px;
        margin-top: 20px;
        margin-bottom: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }
    .my_top_info_head img{
        width: 100px;
        height: 100px;
        border-radius: 50px;
        /*box-shadow: 0px 2px 2px rgba(117,164,245,0.5);*/
    }
    .my_top_info_comppany{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin-left: 24px;
    }

    .company_name{
        font-size: 26px;
        letter-spacing: 2px;
    }

    .company_name_bord{
        font-weight: bold;
    }

    .unmarginleft{
        margin-left: unset !important;
    }

    .my_top_info_comppany .info{
        display: flex;
        flex-direction: row;
        margin-top: 16px;
        margin-left: 4px;
        font-size: 22px;
        color:#A79F9F;
    }

    .my_top_info_comppany .info .icon img {
        width: 14px;
        height: 18px;
    }

    .my_top_info_comppany .info .types{
        margin-left: 8px;
    }

    /*我的需求样式*/
    .my_top_require{
        width: 710px;
        border-top: 2px #F1F1F1 solid;
    }

    .my_top_require .title{
        margin-top: 12px;
        margin-left: 40px;
        margin-right: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        letter-spacing: 2px;
    }
    .my_top_require .title .p1{
        font-size: 28px;
        flex: 1 auto;
        font-weight: bold;
    }
    .my_top_require .title .p2{
        font-size: 22px;
        flex: 1 auto;
        color: #A79F9F;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
    }

    .p2 span{
        width: 96px;
    }

    .p2 img{
        width: 24px;
        height: 24px;
    }

    .my_top_require .sum{
        margin-left: 40px;
        margin-right: 40px;
        margin-bottom: 16px;
        margin-top: 16px;

        display: flex;
        flex-direction: row;
        align-items: center;
        height: 110px;
    }

    .sum .item{
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        letter-spacing: 2px;
        font-size: 26px;
        color: #272525;
    }

    .item .count{
        color:#C50000;
        font-size: 24px;
        font-weight: bold;
        margin-top: 12px;
    }

    .my_menu{
        margin-top: 30px;
        border-radius: 10px;
        box-shadow: 0px 2px 20px rgba(100,100,100,0.5);
        margin-left: 20px;
        margin-right: 20px;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    .my_menu .item{
        display: flex;
        flex-direction: row;
        height: 100px;
        align-items: center;
    }

    .my_menu .item_spliter{
        border-top: 4px #F6F6F6 solid;
    }

    .my_menu .item .item_icon{
        margin-left: 40px;
        height: 40px;
        width: 40px;
    }

    .my_menu .item .item_icon img{
        width: 40px;
        height: 40px;
    }

    .my_menu .item .item_txt{
        font-size: 28px;
        color: #797979;
        letter-spacing: 2px;
        margin-left: 30px;
        flex: 1;
    }

    .my_menu .item .item_goto2{
        height: 40px;
        margin-right: 40px;
    }

    .my_menu .item .item_goto2 img{
        width: 40px;
        height: 40px;
    }

    .my_button{
        margin-top: 138px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .my_button div{
        height: 100px;
        margin-left: 20px;
        margin-right: 20px;
        font-size: 26px;
        background-color: #9C0000;
        box-shadow: 0px 2px 4px rgba(100,100,100,0.5);
        letter-spacing: 4px;
        color: white;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .popup_tips{
        border-radius: 10px;
        /*height: 650px;*/
        top:20% !important;
        bottom: 20% !important;
    }
    .read{
        width: 630px;
        border-radius: 10px;
        background-color: white;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .read .read_content{
        font-size: 26px;
        margin-top: 10px;
        margin-left: 10px;
        margin-right: 10px;
        padding-bottom: 10px;
        letter-spacing: 2px;

        border-bottom: 2px #D3D3D3 dashed;
        flex: 1;
        overflow: scroll;
    }

    .read .read_tools{
        height: 100px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .read_tools span{
        margin-right: 8px;
    }

    .read .clickCls{
        color: #D3D3D3;
    }

    .read .clickClsOK{
        color: green;
    }

    .item_txt_wxmini{
        position: relative;
        height: 100%;

    }

</style>

<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
  import keys from "./components/xyg/utils/keys";

  export default {
    "name":"App",
  };
</script>

<style scoped>
  .mint-popup-bottom{
    top:unset !important;
  }
</style>



let baseUrl="";
switch (process.env.VUE_APP_TITLE) {
    case 'local':   // 注意这里的名字要和步骤二中设置的环境名字对应起来
        baseUrl = "http://127.0.0.1:9527/"  //这里是测试环境中的url
        break
    case 'production':
        baseUrl = "http://172.81.209.109:9527/"   //生产环境url
        break

    default:
        baseUrl = "http://xingship.com"  //这里是本地的请求url
}

export default baseUrl;

import Vue from 'vue'
import App from './App.vue'
import moment from "moment";
Vue.prototype.$moment=moment;
import router from "./components/route";
Vue.config.productionTip = false
import { InfiniteScroll } from 'mint-ui';
Vue.use(InfiniteScroll);


import request from "./components/xyg/utils/request";
import keys from "./components/xyg/utils/keys";
Vue.prototype.$post=request.post
Vue.prototype.$upload=request.upload
Vue.prototype.$get=request.get

import wx from 'weixin-js-sdk';
Vue.prototype.$wx=wx;

const weixin={

  config(){
    let ticket_url="/api/common/getWxJsApiTicket";
    let domain=keys.webSite+'/';//http://h5.xingship.com/'; //'http://h5.xingship.com/selectrole' ;//
    let data={url:domain};

    request.post(ticket_url,data)
        .then(rsp=>{
          if(rsp.code==200 && rsp.success){
            try{
              let rspData=rsp.data;
              wx.config({
                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                appId: rspData.appId, // 必填，公众号的唯一标识
                timestamp: rspData.timesTamp, // 必填，生成签名的时间戳
                nonceStr: rspData.nonceStr, // 必填，生成签名的随机串
                signature: rspData.signature,// 必填，签名
                jsApiList: ['previewImage'], // 必填，需要使用的JS接口列表
                openTagList:['wx-open-launch-weapp']
              });
            }
            catch (e) {
              console.log(e);
            }
          }
        })
        .catch(e=>{
          console.log(e);
        })
  },

  error(){
    wx.error(function (res) {
      console.log(res);
    })
  },

  ready(){
    wx.ready(function () {
      console.log('ready...');
    });
  },

  init(){
    this.config();
    this.ready();
    this.error();
  }

};

new Vue({
  router,
  render: h => h(App),
  mounted(){
    weixin.init();
  },
}).$mount('#app')

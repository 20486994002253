<template>
    <div class="carousel-messagebox">
        <transition-group tag="ul" class="removeUl"
                          enter-active-class="animate__animated animate__fadeInRight"
                          leave-active-class="animate__animated animate__fadeOutLeft">
            <li v-show="idx == isCurrent" v-for="(item, idx) in messageList" :key="'li'+idx" :class="['carousel-message']">
                <span class="good">{{item.name}}</span>
            </li>
        </transition-group>
    </div>
</template>

<script>
    import animate from 'animate.css';
    export default {
        name:'broadcast',
        data () {
            return {
                messageList: [],
                isCurrent:0
            }
        },
        methods:{
            autoPlay () {
                // this.messageList = this.initMessage();
                let length = this.messageList.length;
                if (this.isCurrent >= length) {
                    this.isCurrent = 0;
                    this.autoPlay();
                } else {
                    setTimeout(()=>{
                        this.isCurrent++;
                        this.autoPlay();
                    }, 4000)
                }
            },

            initMessage(){
                let url='/api/order/completeOrder';
                let data={
                    openId: "1",
                    selected: "one",
                    type: ""
                };
                this.$post(url,data)
                    .then(rsp=>{
                        if(rsp.code==200 && rsp.success){
                            let rows=rsp.data;
                            if(rows!=null && rows.length>0){
                                rows.forEach(t=>{
                                    this.messageList.push({name:t});
                                });
                                this.autoPlay();
                            }
                        }
                    })
                    .catch(e=>{
                        console.log(e);
                    })

            }
        },
        // watch:{
        //     messageList:function (n,o) {
        //
        //     }
        // },
        mounted(){
            // this.autoPlay();
            this.initMessage();
        }
    }
</script>

<style>
.carousel-messagebox {
    flex:1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 40px;
}
.removeUl{
    padding:0;
    margin:0;
    list-style:none;
    flex:1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position:relative;
    overflow: hidden;
    width: 100%;
}

.carousel-message {
    border-radius: 30px;
    padding-left:16px;
    position: absolute;
    color:#fff;
    display: -webkit-box;
    -webkit-box-pack: left;
    -webkit-box-align: center;
}
.good {
    font-size: 20px;
    color:black;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
    display: block;
    line-height:30px;
    margin-left: 8px;
}


</style>
